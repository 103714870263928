import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//active directory authentication
import { msalConfig } from "./config/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";


// active directory authentication
export const msalInstance = new PublicClientApplication(msalConfig);


ReactDOM.render(
        <App msalInstance={msalInstance} />,
    document.getElementById("root")
);

serviceWorkerRegistration.unregister();



// serviceWorkerRegistration.register({
//     onUpdate: registration => {
//       // alert('New version available!  Ready to update?'); //this was there twice
//       if (registration && registration.waiting) {
//         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//       }
//       window.location.reload();
//     }
//   });
