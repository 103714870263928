import { ReactTabulator } from "react-tabulator";
import React from "react";
import styled from "styled-components";
import {
    setCurrentAnnotationXAction,
    setCurrentPointAction,
    setShowModalAction,
} from "../store/actions/commentActions";
import { useDispatch } from "react-redux";

import { sortDateString } from "../Components/utils";

export const TabulatorDiv = styled.div`
    /* .tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
    white-space: normal;

    
} */
    .tabulator-row {
        display: flex;
    }

    div.tabulator-col {
        border: 1px solid #ccc;
        /* border-right: 1px solid #ccc;
    border-left: 1px solid #ccc; */
    }

    div.tabulator-cell {
        /* white-space: unset; //shows large comments */
    }
`;

export const timeFormating = (time) => {
    let date = new Date(time);
    // convert to string
    let dateString = date.toLocaleString("en-US", { timeZone: "UTC" });
    return dateString;
};

// define column header menu as column visibility toggle
var headerMenu = function () {
    var menu = [];
    var columns = this.getColumns();

    for (let column of columns) {
        // console.log(column)
        // create checkbox element using font awesome icons
        let icon = document.createElement("i");
        icon.classList.add("fas");
        icon.classList.add(
            column.isVisible() ? "fa-check-square" : "fa-square"
        );

        // build label
        let label = document.createElement("span");
        let title = document.createElement("span");

        title.textContent = " " + column.getDefinition().title;

        label.appendChild(icon);
        label.appendChild(title);

        // create menu item
        menu.push({
            label: label,
            action: function (e) {
                // prevent menu closing
                e.stopPropagation();

                // toggle current column visibility
                column.toggle();

                // change menu item icon
                if (column.isVisible()) {
                    icon.classList.remove("fa-square");
                    icon.classList.add("fa-check-square");
                } else {
                    icon.classList.remove("fa-check-square");
                    icon.classList.add("fa-square");
                }
            },
        });
    }

    return menu;
};

const comment_columns = [
    {
        title: "Annotation Time (UTC)",
        field: "x_str",
        minWidth: 200,
        sorter: sortDateString,
        responsive: 4,
    },
    {
        title: "Last Updated (UTC)",
        field: "updated_at_str",
        minWidth: 200,
        sorter: sortDateString,
        responsive: 3,
    },
    { title: "User", field: "account.name", minWidth: 150, responsive: 1 },
    { title: "UDC", field: "label", minWidth: 150, responsive: 9 },
    {
        title: "p2_scada_id",
        field: "p2_scada_id",
        minWidth: 100,
        responsive: 8,
    },
    { title: "Comment", field: "value", responsive: 0, minWidth: 125 },
];

const CommentsTable = (props) => {
    var { comments_dict, mode } = props;

    let ref = React.useRef();

    const dispatch = useDispatch();

    if (comments_dict === undefined) {
        let comments_not_loaded = Object.keys(comments_dict).length === 0;

        if (comments_not_loaded) {
            return null;
        }
    }

    let comments_data = Object.values(comments_dict);

    // filter_comments
    comments_data = comments_data.filter((comment) => {
        return (
            comment.deleted === false && comment.edited_and_replaced === false
        );
    });

    // add headerMenu to columns
    comment_columns.forEach((column) => {
        column.headerMenu = headerMenu;
    });

    comments_data.forEach((comment) => {
        comment.x_str = timeFormating(comment.x);
        comment.updated_at_str = timeFormating(comment.updated_at);
    });

    const downloadCSV = () => {
        // console.log('ref table: ', ref.current); // this is the Tabulator table instance
        ref.current.download("csv", "comments.csv", { delimiter: "," });
    };
    const downloadXLSX = () => {
        ref.current.download("xlsx", "comments.xlsx", { sheetName: "My Data" });
    };

    const rowClick = (e, row) => {
        console.log(row);
        let data = row._row.data;

        // let x = data.x + data.label;
        // if (mode === "fixed_udc") {
        //     x = data.x;
        // }
        let x_pos = new Date(data.x);
            x_pos = x_pos.getTime();
        const current_point = {
            x: x_pos,
            y: data.y === 0 ? 0.001 : data.y,
            // name: el.points[0].data.name,
            p2_scada_id: data.p2_scada_id,
            sensor_id: data.label,
            yaxis: data.yaxis,
        };
        console.log(current_point)
        dispatch(setCurrentPointAction(current_point));
        
        // dispatch(setCurrentAnnotationXAction(x));
        // if (mode === "fixed_udc") {
        //     dispatch(setCurrentAnnotationXAction(current_point.x));
        // } else {
        const current_annotation_x = current_point.x + current_point.sensor_id; 
        dispatch(setCurrentAnnotationXAction(current_annotation_x));
        // }
        dispatch(setShowModalAction(true));
    };

    return (
        <TabulatorDiv className="maincontainer p-3">
            <div className="col m-2">
                <button
                    style={{ marginRight: "10px" }}
                    type="button"
                    onClick={downloadCSV}
                    className="btn btn-primary btn-sm"
                >
                    Download CSV
                </button>
                <button
                    type="button"
                    onClick={downloadXLSX}
                    className="btn btn-primary btn-sm"
                >
                    Download XLSX
                </button>
            </div>

            <ReactTabulator
                data={comments_data}
                onRef={(r) => (ref = r)}
                columns={comment_columns}
                responsiveLayout="hide"
                initialSort={[
                    { column: "updated_at_str", dir: "asc" }, // sort by this after
                    { column: "x_str", dir: "asc" }, // sort by this first
                ]}
                events={{
                    rowClick: rowClick,
                    // downloadDataFormatter:downloadDataFormatter,//not working
                }}
                options={{
                    // layout:"fitData",
                    // layout:"fitColumns",
                    // autoResize:false,
                    // resizableColumns:false,
                    responsiveLayout: true,
                    pagination: "local",
                    paginationSize: 10,
                }}
            />
        </TabulatorDiv>
    );
};

export default CommentsTable;
