/*
 * properties
 * x: object (dictionary with single key)
 * y: object (dictionary with multiple keys)
 * title: string
 *
 */

import React from "react";
import {
  loadMultiTraceData,
  loadMultiTraceLayout,
} from "./multitraceplot_utils.js";
import PlotButtonsAndModals from "bkv-components/SensorPlot/Components/PlotButtonsAndModals";
import Plotly from "plotly.js-gl2d-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

// .hoverlayer .hovertext

const isISO8601_date = (str) => {
    if (typeof str !== 'string') return false;
    return (
        // YYYY-MM-DD
        str.match(
            /^(\d{4})-(\d{2})-(\d{2})$/
        ) !== null
    );
};

const MultiTracePlot = (props) => {
    const { x, y, onAfterPlot, plot_settings, extra_settings, revision } =
        props;

    const plot_data = loadMultiTraceData(x, y, plot_settings, extra_settings);
    const plot_layout = loadMultiTraceLayout(x, plot_settings, extra_settings);

    let sensor_data = {};
    for (let key in x) {
        if (key === 'all' && isISO8601_date(x[key][0])) {
            sensor_data['record_time'] = x[key];
        } else {
            sensor_data['x_'+key] = x[key];
        }
    }
    for (let key in y) {
        sensor_data['y_'+key] = y[key];
    }

    const [enable_plot_buttons, setEnablePlotButtons] = React.useState(false);

    const onAfterPlotInner = (figure) => {
        // setEnablePlotButtons(false);
        setEnablePlotButtons(true);
        onAfterPlot(figure);
    };




    return (
        <div>
            <Plot
                data={plot_data}
                layout={plot_layout}
                style={{ width: "100%", height: "100%" }}
                useResizeHandler={true}
                config={{
                    modeBarButtonsToRemove: ["lasso2d", "select2d"],
                    displaylogo: false,
                    scrollZoom: true,
                    displayModeBar: true, //always show modebar (delete to show only on hover)
                }}
                // onRelayout={onRelayout}
                // onUpdate={onUpdate}

                onAfterPlot={onAfterPlotInner}
                // onAutoSize={onAutosize}
                revision={revision}
            />
            {/* {enable_plot_buttons && <PlotButtonsAndModals
                        plot_layout={plot_layout}
                        // onUpdateResolution={onUpdateResolution}
                        sensor_data={sensor_data}
                        mode={'fixed_well'}
                        // comments_dict={comments_dict}
                    />
            } */}
        </div>
    );
};

export default MultiTracePlot;
