import { checkFacilityFilters } from "bkv-components/FacilitySelection/FacilitySelectBase";
import FacilitySelectWithFilters from "bkv-components/FacilitySelection/FacilitySelectWithFilters";
import { updateCurrentFacility, updateFacilities } from "bkv-components/store_lib/actions/settingsActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const FacilitySelection = ({onSwitchPane}) => {
    // MULTIFACILITYSELECTBASE PROPS
    // const well_data_hashed = useSelector((state) => state.raw_data.well_data_hashed);
    // const well_data = useSelector((state) => state.raw_data.well_data);
    // const active_merrick_ids = useSelector((state) => state.settings.active_merrick_ids);
    // const plot_loading = useSelector((state) => state.settings.plot_loading);
    const active_facility = useSelector((state) => state.settings.active_merrick_id);
    const filters = useSelector((state) => state.settings.filters);


    // const case_1_data = useSelector((state) => state.data.cases_1_data);
    const case_1_headers = useSelector((state) => state.data.cases_1_headers);
    let case_1_headers_hashed = {};
    for (let el of case_1_headers) {
        let i = el.merrick_id;
        case_1_headers_hashed[i] = el;
    }

    //useDispatch
    const dispatch = useDispatch();

    // const handleChange = async (merrick_id, p2_scada_id, is_selected) => {
    //     if (merrick_id) {
    //         // set_active_facility(current_facility_id);

    //         let new_active_merrick_ids = updateActiveP2MerrickIds(
    //             merrick_id,
    //             active_merrick_ids,
    //             10
    //         );
    //         dispatch(updateFacilities(new_active_merrick_ids));
    //     }
    // };

    const handleChange = async (new_selection) => {
        dispatch(updateCurrentFacility(new_selection[0]));
    };
    const onFilterChange = (new_filters) => {
        dispatch(updateCurrentFacility(null));

    }


    const selection = active_facility ? [active_facility] : [];

    
    return (
        <>

        <FacilitySelectWithFilters
            onSwitchPane={onSwitchPane}
            well_data={case_1_headers}
            well_data_hashed={case_1_headers_hashed}
            selection={selection}
            onSelectionChange={handleChange}
            disabled={false}
            filters={filters}
            selector_type="radio"
            onFilterChange={onFilterChange}
        />

            
        </>
    );
};

export default FacilitySelection;
