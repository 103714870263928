import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
    downloadCase1DataAPIAction,
    downloadCase2DataAPIAction,
    setStage3StateAction,
} from "../store/actions/gasDataActions";
import FacilitySelection from "../components/FacilitySelection";
import GasTargetsPlots from "../components/plots/GasTargetsPlots";
import { Button, Tab, Tabs } from "react-bootstrap";
import { setPlotMountedAction, updateCurrentFacility } from "bkv-components/store_lib/actions/settingsActions";
import * as XLSX from "xlsx/xlsx.mjs";
import { saveAs } from "file-saver";
import OverridesTable from "./stage3/OverridesTable";

const Div = styled.div`
    .main-buttons button {
        margin-right: 1rem;
    }
    .title {
        border-bottom: 1px solid;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }
`;

const MainBody = styled.div`
    /* min-height 100vh - height 105.062px */

    min-height: calc(100vh - 105.062px);
`;

const Stage3 = () => {
    const [tab_selected, setTabSelected] = useState("tab0");

    return (
        <div>
            <Tabs id="controlled-tab" activeKey={tab_selected} onSelect={(k) => setTabSelected(k)} className="mb-3">
                <Tab eventKey="tab0" title="Well Review">
                    <CaseStudyReview />
                </Tab>
                <Tab eventKey="tab1" title="Overrides">
                    <OverridesTable />
                </Tab>
            </Tabs>
        </div>
    );
};

export default Stage3;

const CaseStudyReview = () => {
    const dispatch = useDispatch();

    // gasTargetsPlot
    const cases_1_data = useSelector((state) => state.data.cases_1_data);
    const active_facility = useSelector((state) => state.settings.active_merrick_id);
    const data = cases_1_data.filter((el) => el.merrick_id === active_facility);

    // gasDataReducer
    const start_date = useSelector((state) => state.data.start_date);
    const end_date = useSelector((state) => state.data.end_date);
    const states = useSelector((state) => state.data.states);
    const cases_1_headers = useSelector((state) => state.data.cases_1_headers);
    const stage3_state = useSelector((state) => state.data.stage3_state);
    const cases_1_error = useSelector((state) => state.data.cases_1_error);
    const setStage3State = (st) => dispatch(setStage3StateAction(st));
    const resetHeaders = () =>
        dispatch({
            type: "LOAD_CASES_1_HEADERS",
            payload: null,
        });

    const loadCase1Data = () => {
        resetHeaders();
        setStage3State("case1");
        dispatch(updateCurrentFacility(null));
        dispatch(downloadCase1DataAPIAction(start_date, end_date));
    };

    const loadCase2Data = () => {
        resetHeaders();
        setStage3State("case2");
        dispatch(updateCurrentFacility(null));
        dispatch(downloadCase2DataAPIAction(start_date, end_date));
    };

    // {
    //     "merrick_id": 3,
    //     "well_name": "KILE 3H",
    //     "status": "Abandoned",
    //     "code": 3,
    //     "override_volume": 50,
    //     "is_disabled": false,
    //     "is_selected": true,
    //     "include_facility": true
    // }

    const ready = stage3_state !== "none";
    const data_loaded = cases_1_headers !== null;
    const data_exists = data_loaded && cases_1_headers.length > 0;

    if (data_exists) {
        var well_header = cases_1_headers.filter((el) => el.merrick_id === active_facility)[0];
    }

    const n_wells_label = cases_1_headers !== null ? " - (" + cases_1_headers.length + " wells)" : "";

    const title = {
        none: "",
        case1: "Last 7 days production" + n_wells_label,
        case2: "Trend review" + n_wells_label,
    };

    const onAfterPlot = (figure) => {
        dispatch(setPlotMountedAction(true));
    };

    return (
        <Div>
            <div className="d-flex mb-3 align-items-center title">
                <div className="d-flex main-buttons">
                    <Button
                        variant="primary"
                        disabled={states.update_db !== "finished" || !data_loaded}
                        onClick={loadCase1Data}
                    >
                        Last 7 days production review
                    </Button>
                    <Button
                        variant="primary"
                        disabled={states.generate_trends !== "finished" || !data_loaded}
                        onClick={loadCase2Data}
                    >
                        Trend review
                    </Button>
                </div>
                <div>
                    <h2 className="ms-2 mb-0">{title[stage3_state]}</h2>
                </div>
            </div>
            <LoadingAndErrors ready={ready} data_loaded={data_loaded} data_exists={data_exists} error={cases_1_error} />

            {ready && data_exists && (
                <MainBody className="d-flex flex-wrap flex-md-nowrap justify-content-center">
                    <div className="maincontainer col-12 col-md-4 col-lg-6 col-xl-5 col-xxl-4 p-3">
                        <FacilitySelection />
                        <DownloadTemplate
                            data={cases_1_headers}
                            case_id={stage3_state}
                            disabled={cases_1_headers === null || cases_1_headers.length === 0}
                        />
                    </div>

                    <div className={"maincontainer p-3 col-12 col-md-8 col-lg-6 col-xl-7 col-xxl-8"}>
                        <GasTargetsPlots data={data} well_header={well_header} onAfterPlot={onAfterPlot} />
                    </div>
                </MainBody>
            )}
        </Div>
    );
};

const LoadingAndErrors = (props) => {
    const { ready, data_loaded, data_exists, error } = props;

    if (error) {
        return (
            <div className="d-block">
                <h4>Error loading data... </h4>
                <br />
                <p>{error}</p>
            </div>
        );
    }

    if (!ready) {
        return (
            <div className="d-block">
                <h4>Click a button to load a case study... </h4>
                <br />
                <p>(go to Tab 2 if buttons are disabled)</p>
            </div>
        );
    } else {
        if (!data_loaded) {
            return (
                <div className="d-block">
                    <h4>Loading... </h4>
                    <br />
                </div>
            );
        } else {
            if (!data_exists) {
                return (
                    <div className="d-block">
                        <h4>No data found for this case study... </h4>
                        <br />
                    </div>
                );
            } else {
                return null;
            }
        }
    }
};

export const DownloadTemplate = (props) => {
    const { disabled, data, case_id } = props;

    let template_data = data.map((el) => {
        return {
            merrick_id: el.merrick_id,
            code: el.code,
            override_volume: el.override_volume,
            comments: el.comments,
        };
    });

    const onExportToXLSX = () => {
        const worksheet = XLSX.utils.json_to_sheet(template_data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Generate the XLSX file buffer
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        // Convert buffer to a Blob
        const blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Save the file using FileSaver.js or any other method of your choice
        let filename = "template_" + case_id + ".xlsx";
        saveAs(blob, filename);
    };

    return (
        <div>
            <h5 className="ps-2">Excel Template</h5>

            <br />
            <Button variant="primary" disabled={disabled} onClick={onExportToXLSX}>
                Download Template
            </Button>
        </div>
    );
};
