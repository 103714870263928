import React from "react";
import ExcelFileHandler from "./ExcelFileHandler";
import * as XLSX from "xlsx/xlsx.mjs";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { setStage3StateAction, updateOverridesAPIAction } from "../../store/actions/gasDataActions";
import styled from "styled-components";

const Div = styled.div`

h3 {
        border-bottom: 1px solid;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-color: black;
    }
`

const OverridesTable = () => {
    const overrides = useSelector((state) => state.data.overrides);
    const dispatch = useDispatch();
    const setOverrides = (overrides) =>
        dispatch({
            type: "LOAD_OVERRIDES",
            payload: overrides,
        });

    const start_date = useSelector((state) => state.data.start_date);
    const end_date = useSelector((state) => state.data.end_date);
    const analysis_id = start_date + "_" + end_date;

    // const enable_save = dates_ready && overrides.length > 0;

    const override_table = overrides.map((row) => {
        return [row.merrick_id, row.code, row.override_volume, row.comments];
    });

    const dates_ready = start_date !== undefined && end_date !== undefined;

    const onTableChange = (data) => {
        // add analysis_id to overrides_table

        setOverrides(data);
        dispatch(setStage3StateAction("none"));
        if (dates_ready) {
            dispatch(updateOverridesAPIAction(analysis_id, data));
        }
    };

    const onExportToXLSX = () => {
        const worksheet = XLSX.utils.json_to_sheet(overrides);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Generate the XLSX file buffer
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        // Convert buffer to a Blob
        const blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Save the file using FileSaver.js or any other method of your choice
        let filename = "target_review_" + analysis_id + ".xlsx";
        saveAs(blob, filename);
    };

    return (
        <Div>
            <h3>Override Table</h3>

            <ExcelFileHandler data={override_table} onChange={onTableChange} onExport={onExportToXLSX} />
        </Div>
    );
};

export default OverridesTable;
