

export const setShowMenuAction = (show_menu) => {
    return {
        type: "SET_SHOW_MENU",
        payload: show_menu,
    };
};

export const setCurrentPointAction = (current_point) => {
    return {
        type: "SET_CURRENT_POINT",
        payload: current_point,
    };
};

export const setXPosAction = (xPos) => {
    return {
        type: "SET_X_POS",
        payload: xPos,
    };
};

export const setYPosAction = (yPos) => {
    return {
        type: "SET_Y_POS",
        payload: yPos,
    };
};

export const setCurrentAnnotationXAction = (current_annotation_x) => {
    return {
        type: "SET_CURRENT_ANNOTATION_X",
        payload: current_annotation_x,
    };
};



export const setShowModalAction = (show_modal) => {
    return {
        type: "SET_SHOW_MODAL",
        payload: show_modal,
    };
};



// MORE COMPLEX ACTIONS

export const onUnhoverAction = (el) => {
    return async (dispatch, getState) => {
        // get current_point from state
        const { current_point } = getState().comments;
        // exit menu
        if (current_point) {
            let xdate_secs = new Date(el.points[0].x).getTime() / 1000;
            let xcurrent_secs = new Date(current_point.x).getTime() / 1000;

            // console.log(Math.abs( xdate_secs - xcurrent_secs ))
            if (Math.abs(xdate_secs - xcurrent_secs) > 400) {
                dispatch(setShowMenuAction(false));
            }
        }

        //IMPROVE detect click outside of menu to exit menu
    };
};



// DATABASE ACTIONS
