import React from 'react'

import GasTargets from '../applications/gas_targets/GasTargets'

function Portal() {
  return (
    <GasTargets/>
  )
}

export default Portal