

import React from 'react'
import styled from 'styled-components';


const Div = styled.div`
min-height: 100vh;

    .maincontainer {
        background-color: white;
        /* border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; */
        border: 1px solid #dee2e6;
        border-radius: 5px;
    }

    .left-container {
        float: left;
        width: 40rem;
        padding: 1rem;

        /* to be removed */
        /* height: 100vh; */
        border: 1px;
        background-color: white;
    }

    .right-container {
        width: calc(100vw - 40rem);
        background-color: gray;
        padding: 1rem;
    }

    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
            "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
            "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
    }

    .plot-container .hoverlayer .hovertext text.nums {
        word-wrap: normal;
        width: 300px;
    }

    .plot-container .hoverlayer .hovertext {
        fill-opacity: 0.6;
        
    }

    background-color: gray;

    h1,
    h2,
    h3,
    h4 {
        color: #094e8f !important;
    }

    h5 {
        color: #5186b2 !important;
    }

    /* TABLE */

    table th,
    table td {
        /* font-weight: unset; */
        white-space: nowrap;
    }

    #myDiv {
        /* border: 1px solid black; */
        min-width: 600px;
    }
`;

function Layout({ children }) {
  return (
    <Div className="d-flex flex-column">
        {children}
    </Div>
  )
}

export default Layout