import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import Stage1 from "./stages/Stage1";
import Stage3 from "./stages/Stage3";
import Stage2 from "./stages/Stage2";
import Stage4 from "./stages/Stage4";
import { useSelector } from "react-redux";
import Stage0 from "./stages/Stage0";

const MainBody = styled.div`
    /* min-height 100vh - height 105.062px */

    min-height: calc(100vh - 105.062px);

    .nav-tabs .nav-item button {
        background-color: #bbb;
        border-bottom: 1px solid #fff;
    }
    .nav-tabs .nav-link.active {
        background-color: #fff;
        border-bottom: 1px solid #fff;
    }

    .mycontainer {
        background-color: #eee;
    }

    .nav-tabs {
        border-bottom: none;
    }

    .tab-content {
        //round edges except top left
        border-radius: 0.5rem;
        border: 1px solid #ddd;
    }
`;

const MainTabs = () => {
    const [tab_selected, setTabSelected] = useState("tab0");

    const start_date = useSelector((state) => state.data.start_date);
    const end_date = useSelector((state) => state.data.end_date);
    const rds_status = useSelector((state) => state.rds.rds_status);


    let stage = 4;
    if (rds_status !== "available") {
        stage = 0;
    } else if (start_date === undefined || end_date === undefined) {
        stage = 1;
    } else if (start_date !== undefined && end_date !== undefined) {
        stage = 4;
    }

    console.log(stage)


    return (
        <div>
            <MainBody className="maincontainer d-flex flex-wrap flex-md-nowrap justify-content-center">
                <div className="col-12 p-3 mycontainer">
                    <Tabs
                        id="controlled-tab"
                        activeKey={tab_selected}
                        onSelect={(k) => setTabSelected(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="tab0" title="Tab 0">
                                <Stage0 />
                        </Tab>
                        {stage >= 1 && (
                            <Tab eventKey="tab1" title="Tab 1">
                                <Stage1 />
                            </Tab>
                        )}
                        {stage >= 2 && (
                            <Tab eventKey="tab2" title="Tab 2">
                                {
                                    tab_selected === "tab2" && 
                                    <Stage2 />
                                }
                            </Tab>
                        )}
                        {stage >= 3 && (
                            <Tab eventKey="tab3" title="Tab 3">
                                {/* <div className="d-flex flex-wrap flex-md-nowrap justify-content-center">hello</div> */}
                                <Stage3 />
                            </Tab>
                        )}
                        {stage >= 4 && (
                            <Tab eventKey="tab4" title="Tab 4">
                                <Stage4/>
                                </Tab>
                                )}
                    </Tabs>
                </div>
            </MainBody>
        </div>
    );
};

export default MainTabs;
