import React, { useEffect, useState } from "react";
import styled from "styled-components";
import WellElements from "./WellElements";

const Div = styled.div`


`;
const MAX_SELECTED_FACILITIES = 5;

/*
* Shape of data:
* [
*   {
*       merrick_id: "123",
*       well_name: "Well 1",
*       is_cached: false,
*   },
*   {
*       merrick_id: "456",
*       well_name: "Well 2",
*       is_cached: true,
*   },
* ]
*
* Shape of selection:
* ["123", "456"]
*/

const sortBySelectedFirst = (data, selection) => {
    let sorted_data_aux = data;
    sorted_data_aux.sort((a, b) => {
        let is_selected_a =
            selection &&
            selection.includes(a.merrick_id);
        if (!is_selected_a) {
            is_selected_a = false;
        }
        let is_selected_b =
            selection &&
            selection.includes(b.merrick_id);
        if (!is_selected_b) {
            is_selected_b = false;
        }
        return is_selected_b - is_selected_a;
    });
        
    return sorted_data_aux;
}

const filterByQueryAndSelection = (facilities, query,selection) => {
        let filtered_data_aux = facilities.filter((facility) => {
            let is_substring = (facility.merrick_id +" "+ facility.well_name)
            .toLowerCase()
            .includes(query.toLowerCase());

            let is_selected = selection && selection.includes(facility.merrick_id);
            if (!is_selected) {
                is_selected = false;
            }

            return (is_substring) || is_selected;
        });
        return filtered_data_aux;
}



const FacilitySelectBase = (props) => {
    const {
        title,
        selection,
        data,
        disabled,
        onSelectionChange,
        onSwitchPane,
        max_selected = MAX_SELECTED_FACILITIES,
        selector_type = "checkbox",
    } = props;

    const [query, setquery] = useState("");
    const [processed_data, setProcessedData] = useState(data);


    const updateQuery = (e) => {
        setquery(e.target.value);
    };

    // first filter the data by query and selection
    useEffect(() => {
        // console.time('filtering and sorting data')
        let filtered_data_aux = filterByQueryAndSelection(data,query,selection)
        // sort data according to selection
        let sorted_data_aux = filtered_data_aux;
        if (selector_type === "checkbox") {
            // NOTE: THIS WAS MODIFIED TO ADD A DIVIDER
            // sorted_data_aux = sortBySelectedFirst(filtered_data_aux,selection)
        }
        // keep only first 100
        // filtered_data_aux = sorted_data_aux.slice(0, 500);
        filtered_data_aux = sorted_data_aux;
        // add additional info to data
        filtered_data_aux.map((facility) => {
            let is_selected = selection && selection.includes(facility.merrick_id);
            let is_disabled = (!is_selected && selection && selection.length >= max_selected);
            facility.is_disabled = is_disabled;
            facility.is_selected = is_selected;
            facility.include_facility = true;
        });
        // console.log('data filtered by query and selection. data length: ',data.length)
        setProcessedData(filtered_data_aux)
        // console.timeEnd('filtering and sorting data')
    }, [data,query, selection]);


    const handleChange = (merrick_id) => {

        if (selector_type === "checkbox") {

            if (!selection){
                const new_selection = [merrick_id];
                onSelectionChange(new_selection);
                return;
            }

            if (selection.includes(merrick_id)) {
                const new_selection = selection.filter(
                    (id) => id !== merrick_id
                );
                onSelectionChange(new_selection);
                return;
            } else {
                const new_selection = [...selection, merrick_id];
                onSelectionChange(new_selection);
                return;
            }
        } else if (selector_type === "radio") {
            onSelectionChange([merrick_id]);
            return;
        }


    };


    return (
        <Div>
            <h4
            className="justify-content-between d-flex"
            ><span>{title} 
            </span>
            {onSwitchPane &&
                <span title="Switch Selection Type"
                className="btn" onClick={onSwitchPane}>⇋
                </span>
                }
                </h4>
            <hr />

            {/* <!-- might use selectize --> */}

            <div className="input-group rounded">
                <input
                    type="search"
                    className="form-control form-control-sm rounded"
                    value={query}
                    onChange={updateQuery}
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-addon"
                />
                <span className="input-group-text border-0" id="search-addon">
                    <i className="fas fa-search"></i>
                </span>
            </div>
            
                {processed_data &&
                <WellElements
                    processed_data={processed_data}
                    handleChange={handleChange}
                    selector_type={selector_type}
                    disabled={disabled}
                />
                    }
            
        </Div>
    );
};

export default FacilitySelectBase;


