const initState = {
    rds_status: undefined,
    checking_status: false,
    rds_refreshing: false,
}

const gasRdsStatus = (state = initState, action) => {
    switch (action.type) {
        case "LOAD_RDS_STATUS":
            return {
                ...state,
                rds_status: action.payload,
            };
        case "SET_CHECKING_STATUS":
            return {
                ...state,
                checking_status: action.payload,
            };
        case "SET_RDS_REFRESHING":
            return {
                ...state,
                rds_refreshing: action.payload,
            };
        default:
            return state;
    }
}

export default gasRdsStatus;