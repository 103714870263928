import styled from "styled-components";
import MultiTracePlot from "./MultiTracePlot";
import React from "react";

const Div = styled.div`

div.mytable {
    padding-top: 20px;
    padding-left: 60px;
    padding-right: 60px;
}



// set row height
div.mytable table tr {
    height: 1px;
}

div.mytable table tr td {
    padding: 0px !important;
    border: 3px solid;
    border-color: white;
}


//second column
div.mytable table tr td:nth-child(2) {
    /* padding-left: 10px; */
    background-color: #f5f5f5;
    min-width: 170px;
    //center text
    text-align: center;
}

// set fixed width for table
div table {
    width: 400px !important;
    table-layout: fixed;
    

}

// set fixed width for 1st column
div.mytable table tr td:nth-child(1) {
    width: 150px !important;
    //center text
}

// set fixed width for 2nd column
div.mytable table tr td:nth-child(2) {
    /* max-width: 400px; */
    white-space: nowrap;
    overflow: auto;
    /* overflow:  */
    /* text-overflow: clip; */
    text-align: left;
    padding-left: 10px !important;
}


`


// sample data
// [
//     {
//         "merrick_id": 3,
//         "production_date": "2023-02-01",
//         "gas_production": 710.94,
//         "gas_forecast": 653.2383775,
//         "gas_target": 699.9849007142857,
//         "gas_budget": 653.2383775,
//         "status": "Shut In",
//         "case": "3 - Shut In"
//     },
//     {
//         "merrick_id": 3,
//         "production_date": "2023-02-02",
//         "gas_production": 505.03,
//         "gas_forecast": 653.2383775,
//         "gas_target": 699.9849007142857,
//         "gas_budget": 653.2383775,
//         "status": "Shut In",
//         "case": "3 - Shut In"
//     },
//     {
//         "merrick_id": 3,
//         "production_date": "2023-02-03",
//         "gas_production": 505.03,
//         "gas_forecast": 653.2383775,
//         "gas_target": 699.9849007142857,
//         "gas_budget": 653.2383775,
//         "status": "Shut In",
//         "case": "3 - Shut In"
//     },

// ]

const GasTargetsPlots = (props) => {
    const { well_header , data, onAfterPlot} = props;

    // const cases_1_data = useSelector((state) => state.data.cases_1_data);
    // const active_facility = useSelector((state) => state.settings.active_merrick_id);

    // const data = cases_1_data.filter((el) => el.merrick_id === active_facility);

    const data_x = { production_date: data.map((el) => el.production_date) };


    if (data.length === 0) {
        return (
            <div className="element p-4">
                <h4>Select a well...</h4>
            </div>
        );
    }
    const data_y = {};
    for (let key of Object.keys(data[0])) {
        if (key !== "production_date" && key !== "merrick_id") {
            data_y[key] = data.map((el) => el[key]);
        }
    }

    const plot_settings = {
        connectgaps: false,
        plot_height: 900,
    };

    const engineering_settings = {
        // x_title: 'production_date',
        // y_title: 'gas_production',
        base_unit: "mfc",
        x_type: "date",
        // title: well_header.merrick_id + " - " + well_header.well_name
        // y_type: 'log',
    };



    // console.log(data);
    return (
        <Div>
            <div className="mytable">
                <table className="table">
                    <tbody>
                        <tr>
                            <td>merrick_id:</td>
                            <td>{well_header?.merrick_id}</td>
                        </tr>
                        <tr>
                            <td>well_name:</td>
                            <td>{well_header?.well_name}</td>
                        </tr>
                        <tr>
                            <td>status:</td>
                            <td>{well_header?.status}</td>
                        </tr>
                        {well_header?.code !== undefined && (
                        <tr>
                            <td>code:</td>
                            <td>{well_header?.code}</td>
                        </tr>
                        )}
                        {well_header?.override_volume  !== undefined && (
                        <tr>
                            <td>override_volume:</td>
                            <td>{well_header?.override_volume}</td>
                        </tr>
                        )}
                        {well_header?.comments !== undefined && (
                        <tr>
                            <td>comments:</td>
                            <td>{well_header?.comments}</td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="element p-4">
                <MultiTracePlot
                    x={data_x}
                    y={data_y}
                    plot_settings={plot_settings}
                    extra_settings={engineering_settings}
                    onAfterPlot={onAfterPlot}
                    // revision={revision}
                />
            </div>
        </Div>
    );
};

export default GasTargetsPlots;

const axis_settings = {
    production_date: {
        type: "date",
        title: "Production Date",
        unit: "",
    },
    gas_production: {
        type: "log",
        title: "Gas Production (mcf)",
        unit: "mcf",
    },
    water_production: {
        type: "log",
        title: "Water Production (bbl)",
        unit: "bbl",
    },
    gas_cumulative: {
        type: "log",
        title: "Gas Cumulative (mcf)",
        unit: "mcf",
    },
    water_cumulative: {
        type: "log",
        title: "Water Cumulative (bbl)",
        unit: "bbl",
    },
    sqrt_t: {
        type: "log",
        title: "Square Root Time (√d)",
        unit: "√d",
    },
    t: {
        type: "log",
        title: "Time since IP (days)",
        unit: "days",
    },
    reciprocal_gas_rate: {
        type: "log",
        title: "Reciprocal Gas Rate (1/mcf)",
        unit: "1/mcf",
    },
    mbt: {
        type: "log",
        title: "Material Balance Time (MBT)",
        unit: "",
    },
};

const v1_plots = {
    gas_vs_date: {
        y: "gas_production",
        x: "production_date",
        x_scale: "date",
        y_scale: "log",
    },
    gas_vs_cumulative_gas: {
        y: "gas_production",
        x: "gas_cumulative",
        x_scale: "log",
        y_scale: "log",
    },
    inverse_gas_vs_sqrt_date: {
        y: "reciprocal_gas_rate",
        x: "sqrt_t",
        x_scale: "linear",
        y_scale: "log",
    },
    water_vs_cumulative_water: {
        y: "water_production",
        x: "water_cumulative",
        x_scale: "log",
        y_scale: "log",
    },
    gas_vs_mbt: {
        y: "gas_production",
        x: "mbt",
        x_scale: "log",
        y_scale: "log",
    },
};
