import React from 'react'
import AdminDashboardApp from '../applications/admin_dashboard/AdminDashboardApp'

export const AdminDashboard = () => {
  return (
    <AdminDashboardApp/>
  )
}

export default AdminDashboard
