
export const updateCurrentFacility = (facility_id) => {
    return async (dispatch, getState) => {
        if (!facility_id){
            return dispatch({ type: "UPDATE_CURRENT_FACILITY", payload: { facility_id: null } });
        }
        if (!facility_id.constructor === String) {
            return (
                { type: "CREATE_SETTINGS_ERROR" },
                "facility_id must be a string"
            );
        }
        // dispatch(setPlotMountedAction(false));
        return dispatch({ type: "UPDATE_CURRENT_FACILITY", payload: { facility_id } });
    };
};


export const updateFacilities = (facilities) => {
    return async (dispatch, getState) => {
        if (!facilities.constructor === String) {
            return (
                { type: "CREATE_SETTINGS_ERROR" },
                "facility_id must be a string"
            );
        }
        // dispatch(setPlotMountedAction(false));
        return dispatch({ type: "UPDATE_CURRENT_FACILITIES", payload: { facility_ids: facilities } });
    };
};

export const updateFilterState = (filter) => {
    if (!filter.constructor === Object) {
        return { type: "CREATE_SETTINGS_ERROR" }, "filter must be a dictionary";
    }

    return { type: "UPDATE_FILTER_STATE", payload: { filter } };
};

export const setPlotMountedAction = (plot_mounted) => {
    if (!plot_mounted.constructor === Boolean) {
        return (
            { type: "CREATE_SETTINGS_ERROR" }, "plot_mounted must be a boolean"
        );
    }

    return { type: "UPDATE_PLOT_MOUNTED", payload: plot_mounted };
};


export const setUserPlotSettingsAction = (user_plot_settings) => {
    if (!user_plot_settings.constructor === Object) {
        return (
            { type: "CREATE_SETTINGS_ERROR" }, "user_plot_settings must be a dictionary"
        );
    }

    return { type: "UPDATE_USER_PLOT_SETTINGS", payload: user_plot_settings };
}

export const updateCurrentUDC = (udc) => {
   return { type: "UPDATE_CURRENT_UDC", payload: { udc } };
}


export const setPlotLoadingAction = (plot_loading) => {
    if (!plot_loading.constructor === Boolean) {
        return (
            { type: "CREATE_SETTINGS_ERROR" }, "plot_loading must be a boolean"
        );
    }

    return { type: "UPDATE_PLOT_LOADING", payload: plot_loading };
};