const initState = {
    start_date: undefined,
    end_date: undefined,
    cases_1_headers: [],
    cases_1_data: [],
    cases_1_error: false,
    analysis_list: [],
    overrides: [],
    states: [],
    stage3_state: "none",
    loading_states: false,
    calling_get_state: false,
    // final_target_output_csv: null,
};

const gasDataReducer = (state = initState, action) => {
    switch (action.type) {
        case "LOAD_CASES_1_HEADERS":
            return {
                ...state,
                cases_1_headers: action.payload,
            };
        case "LOAD_CASES_1_DATA":
            return {
                ...state,
                cases_1_data: action.payload,
            };
        case "LOAD_CASES_1_ERROR":
            return {
                ...state,
                cases_1_error: action.payload,
            };
        case "SET_START_DATE":
            return {
                ...state,
                start_date: action.payload,
            };
        case "SET_END_DATE":
            return {
                ...state,
                end_date: action.payload,
            };
        case "LOAD_ANALYSIS_LIST":
            return {
                ...state,
                analysis_list: action.payload,
            };
        case "LOAD_OVERRIDES":
            return {
                ...state,
                overrides: action.payload,
            };
        case "LOAD_STATES":
            return {
                ...state,
                states: action.payload,
            };
        case "SET_STAGE3_STATE":
            return {
                ...state,
                stage3_state: action.payload,
            };
        case "SET_LOADING_STATES":
            return {
                ...state,
                loading_states: action.payload,
            };
        case "SET_CALLING_GET_STATE":
            return {
                ...state,
                calling_get_state: action.payload,
            };
        // case "SET_FINAL_TARGET_OUTPUT_CSV":
        //     return {
        //         ...state,
        //         final_target_output_csv: action.payload,
        //     };
        default:
            return state;
    }
}

export default gasDataReducer;

