import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import CommentsTable from "../Comments/CommentsTable";
import DataDownloadTable from "./DataDownloadTable";

const DivButtons = styled.div`
    .btn {
        margin-right: 5px;
    }
`;

function PlotButtonsAndModals(props) {
    const {
        plot_layout,
        onUpdateResolution,
        sensor_data,
        plot_mounted,
        comments_dict,
    } = props;

    // update resolution when clicking update resolution button
    const updateResolution = () => {
        let start_time = new Date(plot_layout.xaxis.range[0]);
        let end_time = new Date(plot_layout.xaxis.range[1]);

        // dispatch(downloadSensorMultiwellData(active_p2_scada_ids, active_udc, start_time, end_time));
        onUpdateResolution(start_time, end_time);
        // dispatch(fetchCommentsDBAction(p2_scada_id, start_time, end_time));
    };

    // position button on top of plot
    const buttons_ref = React.useRef(null);
    const main_ref = React.useRef(null);

    useEffect(() => {
        // get parent div selector
        const parent_div = main_ref.current.parentElement;

        // next 2 elements are generated by plotly
        let inner_plot_div = parent_div.querySelector(
            "g.draglayer.cursor-crosshair"
        );
        let plotly_container = parent_div.querySelector(
            ".user-select-none.svg-container"
        );
        if (!inner_plot_div || !plotly_container) {
            return;
        }
        let inner_rect = inner_plot_div.getBoundingClientRect();
        let plotly_rect = plotly_container.getBoundingClientRect();
        let offset_y = inner_rect.top - plotly_rect.top;
        let offset_x = inner_rect.left - plotly_rect.left;
        // setup relative positioning
        buttons_ref.current.style.top = `${offset_y - 35}px`;
        buttons_ref.current.style.left = `${offset_x + 20}px`;
        // insert buttons_ref into inner_plot_div
        plotly_container.appendChild(buttons_ref.current);
    }, [plot_mounted]);

    const [show_comments_modal, setShowCommentsModal] = React.useState(false);
    const [show_download_modal, setShowDownloadModal] = React.useState(false);

    const handleCloseCommentsModal = () => {
        setShowCommentsModal(false);
    };

    const handleCloseDownloadModal = () => {
        setShowDownloadModal(false);
    };

    return (
        <div ref={main_ref}>
            <Modal
                centered
                size="xl"
                show={show_comments_modal}
                onHide={handleCloseCommentsModal}
            >
                <Modal.Header>
                    <Modal.Title>Comments</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <CommentsTable comments_dict={comments_dict} />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseCommentsModal}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={show_download_modal}
                onHide={handleCloseDownloadModal}
                size="xl"
                // fullscreen={true}
            >
                <Modal.Header>
                    <Modal.Title>Download Sensor Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DataDownloadTable sensor_data={sensor_data} />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseDownloadModal}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DivButtons
                ref={buttons_ref}
                style={{ position: "absolute", left: 20, top: -30 }}
                // style={{ position: "absolute", display: "block" }}
                // style={{ position: "absolute", display: "block" }}
            >
                {onUpdateResolution && (
                    <>
                        <button
                            type="button"
                            onClick={updateResolution}
                            className="btn btn-outline-secondary btn-sm d-sm-inline-block d-none"
                        >
                            Update Resolution
                        </button>
                        <i
                            className="fa-solid fa-arrows-rotate btn btn-outline-secondary btn-sm d-sm-none"
                            onClick={updateResolution}
                            title="Update Resolution"
                            aria-label="Update Resolution"
                        />
                    </>
                )}

                {sensor_data && (
                    <>
                        <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm d-sm-inline-block d-none"
                            onClick={() => setShowDownloadModal(true)}
                        >
                            Download Data
                        </button>
                        <i
                            className="fa fa-download btn btn-outline-secondary btn-sm d-sm-none"
                            onClick={() => setShowDownloadModal(true)}
                            aria-hidden="true"
                            title="Download Data"
                        />
                    </>
                )}
                {comments_dict && (
                    <>
                        <button
                            type="button"
                            onClick={() => setShowCommentsModal(true)}
                            className="btn btn-outline-secondary btn-sm d-sm-inline-block d-none"
                        >
                            Show Comments
                        </button>
                        <i
                            className="fa fa-comments btn btn-outline-secondary btn-sm d-sm-none"
                            aria-hidden="true"
                            onClick={() => setShowCommentsModal(true)}
                            title="Show Comments"
                        />
                    </>
                )}
            </DivButtons>
        </div>
    );
}

export default PlotButtonsAndModals;
