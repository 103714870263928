const initState = {
    loading: false,
    headers: [],
    raw_data: undefined,
    // trend_data: [],
}

const adminDataReducer = (state = initState, action) => {
    switch (action.type) {
        case "LOAD_HEADERS":
            return {
                ...state,
                headers: action.payload,
            };
        case "LOAD_RAW_DATA":
            return {
                ...state,
                raw_data: action.payload,
            };
        // case "LOAD_TREND_DATA":
        //     return {
        //         ...state,
        //         trend_data: action.payload,
        //     };
        case "SET_LOADING":
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
}

export default adminDataReducer;