
import { loginRequest } from "../config/authConfig";


function isMobile() {
    try {
        if (
            /Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            return true;
        }
        return false;
    } catch (e) {
        console.log("Error in isMobile");
        return false;
    }
}

export const signIn = async (instance) => {
    console.log("signing in1");
        // check if mobile or desktop
        const is_mobile = isMobile();

        if (!is_mobile) {
            let res = await instance.loginPopup(loginRequest)
            console.log(res)
        } else {
            // mobile
            instance
                .loginRedirect(loginRequest)
        }
};

export const signOut = async (instance) => {
        // check if mobile or desktop
        const is_mobile = isMobile();

        if (!is_mobile) {
            try {
                await instance
                    .logoutPopup();
            } catch (err) {
                console.log("Error in signOut", err);
            }
        } else {
            try {
                await instance
                    .logoutRedirect();
            } catch (err) {
                console.log("Error in signOut", err);
            }
        }
  };