import React from "react";
import FilterCheckBox from "./FilterCheckBox";

const AdditionalFilters = (props) => {
    const { filters, onChange } = props;
    return (
        <div>
            <h5 className="ps-2">Additional Filters</h5>

            <br />

            <div>
                {filters.map((filter, index) => (
                    <div key={index}>
                        <FilterCheckBox
                            name={filter.name}
                            id={filter.id}
                            options={filter.options}
                            active_options={filter.active_options}
                            onChange={onChange}
                        />
                        <br />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdditionalFilters;
