// export const sqsGenerateTrendsAPIAction = (start_date,end_date) => {
//     return async (dispatch, getState) => {
//         const { headers, api_endpoint } = await getApiDefaults();

import axios from "axios";
import { getApiDefaults } from "bkv-components/store_lib/actions/dataActions";

export const rdsStartInstanceAPIAction = () => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        try {
            var response = await axios.get(api_endpoint + "/rds/start_instance",
                { headers: headers }
            );
        } catch (e) {
            alert(e.message)
        }
    };
}

export const rdsStopInstanceAPIAction = () => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        try {
            var response = await axios.get(api_endpoint + "/rds/stop_instance",
                { headers: headers }
            );
        } catch (e) {
            alert(e.message)
        }
    };
}

export const rdsRebootInstanceAPIAction = () => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        try {
            var response = await axios.get(api_endpoint + "/rds/reboot_instance",
                { headers: headers }
            );
        } catch (e) {
            alert(e.message)
        }
    };
}

export const rdsCheckInstanceAPIAction = () => {
    return async (dispatch, getState) => {
        
        try {
            const { headers, api_endpoint } = await getApiDefaults();

            dispatch({
                type: "SET_CHECKING_STATUS",
                payload: true,
            });

            var response = await axios.get(api_endpoint + "/rds/check_instance",
                { headers: headers }
            );
            let status = response.data[0];
            console.log(status)

            dispatch({
                type: "LOAD_RDS_STATUS",
                payload: status,
            });


        } catch (e) {
            alert(e.message)
        } finally {
            dispatch({
                type: "SET_CHECKING_STATUS",
                payload: false,
            });
        }
    };
}

const setRdsRefreshingAction = (rds_refreshing) => {
    return async (dispatch, getState) => {
        await dispatch({
            type: "SET_RDS_REFRESHING",
            payload: rds_refreshing,
        });
    };
};



let interval = null;

export const rdsCheckContinuoslyAPIAction = (timeout=600) => {
    // default timeout is 10 minutes
    return async (dispatch, getState) => {

        let elapsed_time = 0;
        let initial_time = new Date().getTime();

        let call_in_progress = false;

        clearInterval(interval);
        await dispatch(setRdsRefreshingAction(true));


        interval = setInterval(async () => {

            if (!call_in_progress) {
                call_in_progress = true;
                await dispatch(rdsCheckInstanceAPIAction());
                call_in_progress = false;
            }

            let current_time = new Date().getTime();
            elapsed_time = current_time - initial_time;
            console.log("elapsed_time: " + elapsed_time)
            
            if (elapsed_time > timeout*1000) {
                console.log("clearing interval due to timeout")
                clearInterval(interval);
                await dispatch(setRdsRefreshingAction(false));
            }

        }, 4000);
    }
}

export const clearRdsCheckContinuoslyAPIAction = () => {
    return async (dispatch, getState) => {
        clearInterval(interval);
        await dispatch(setRdsRefreshingAction(false));
    }
}




// export const getStatesContinuoslyAPIAction = (analysis_id,timeout=5) => {
//     return async (dispatch, getState) => {

//         let elapsed_time = 0;
//         let initial_time = new Date().getTime();

//         let call_in_progress = false;

//         clearInterval(interval);
//         await dispatch(setLoadingStatesAction(true));

//         interval = setInterval(async () => {

//             if (!call_in_progress) {
//                 call_in_progress = true;
//                 await dispatch(getStatesAPIAction(analysis_id));
//                 call_in_progress = false;
//             }


//             let current_time = new Date().getTime();

//             elapsed_time = current_time - initial_time;
//             console.log("elapsed_time: " + elapsed_time)

//             if (elapsed_time > timeout*1000) {
//                 console.log("clearing interval due to timeout")
//                 clearInterval(interval);
//                 await dispatch(setLoadingStatesAction(false));
//             }

//         }, 2000);
//     }
// }

// export const clearStatesContinuoslyAPIAction = () => {
//     return async (dispatch, getState) => {
//         clearInterval(interval);
//         await dispatch(setLoadingStatesAction(false));
//     }
// }