import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    clearStatesContinuoslyAPIAction,
    getStatesAPIAction,
    getStatesContinuoslyAPIAction,
    setLoadingStatesAction,
    sqsDeleteAllMessagesAPIAction,
    sqsGenerateRemainingTrendsAPIAction,
    sqsGenerateTrendsAPIAction,
    sqsUpdateDBAPIAction,
} from "../store/actions/gasDataActions";
import styled from "styled-components";

const Div = styled.div`
    .title,
    h3 {
        border-bottom: 1px solid;
        margin-bottom: 2rem;
    }

    form {
        margin-bottom: 2rem;
    }

    button {
        max-width: 80%;
    }

    .buttons button {
        margin-right: 1rem;
    }

    .fields input {
        background-color: #e9ecef;
    }

    .ids-with-errors > div > ul {
        background-color: #e9ecef;
        max-height: 100px;
        overflow-y: scroll;
        min-height: 100px;
    }
    .ids-with-errors li {
        list-style-type: circle;
    }
`;

// analysis_id:"2023-02-01_2023-06-25"
// generate_trends:
// "pending"
// trends_batch_id
// :
// "LJOZI94ZDON02MS"
// trends_batch_processed
// :
// 0
// trends_batch_size
// :
// 3
// update_db
// :
// "ready"

let my_timeout;

const READY_LIST = ["pending", "finished", "error"];

const Stage2 = (props) => {
    const [trends_status, setTrendsStatus] = React.useState("none");
    const [update_db_status, setUpdateDBStatus] = React.useState("none");

    const dispatch = useDispatch();
    const start_date = useSelector((state) => state.data.start_date);
    const end_date = useSelector((state) => state.data.end_date);
    const analysis_id = start_date + "_" + end_date;
    const states = useSelector((state) => state.data.states);
    const loading_states = useSelector((state) => state.data.loading_states);
    const calling_get_state = useSelector((state) => state.data.calling_get_state);

    const handleUpdateDB = async () => {
        if (states.messages_on_queue > 0) {
            alert("There are messages on the queue. Please wait until they are processed");
            return;
        }
        console.log("Update DB");
        setUpdateDBStatus("started");
        await dispatch(setLoadingStatesAction(true));
        await dispatch(sqsUpdateDBAPIAction(start_date, end_date));
        dispatch(getStatesContinuoslyAPIAction(analysis_id, 300));
    };

    const handleRefreshState = async () => {
        console.log("Refresh State");
        // await dispatch(getStatesAPIAction(analysis_id));
        await dispatch(getStatesContinuoslyAPIAction(analysis_id, 3600));
    };

    const stopContinuousRefresh = async () => {
        console.log("Stop Continuous Refresh");
        clearTimeout(my_timeout);
        await dispatch(clearStatesContinuoslyAPIAction());
    };

    const handleGenerateTrends = async () => {
        console.log("Generate Trends");
        setTrendsStatus("started");
        await dispatch(setLoadingStatesAction(true));
        await dispatch(sqsGenerateTrendsAPIAction(start_date, end_date));
        await dispatch(getStatesContinuoslyAPIAction(analysis_id, 3600));
    };

    const handleGenerateRemainingTrends = async () => {
        console.log("Generate Remaining Trends");
        setTrendsStatus("started");
        await dispatch(setLoadingStatesAction(true));
        await dispatch(sqsGenerateRemainingTrendsAPIAction(start_date, end_date));
        await dispatch(getStatesContinuoslyAPIAction(analysis_id, 3600));
    };

    const handleDeleteAllMessages = async () => {
        console.log("Delete All Messages");
        dispatch(sqsDeleteAllMessagesAPIAction());
    };

    const refreshing = loading_states || calling_get_state;

    const ready = start_date && end_date;
    const update_db_ready = READY_LIST.includes(update_db_status) && ready;
    const generate_trends_ready =
        READY_LIST.includes(trends_status) && ready && update_db_status === "finished" && !refreshing;

    const retry_trends_ready = !refreshing && states.trends_batch_processed > 5 && update_db_status === "finished";

    const messages_on_queue = trends_status === "started" ? "deleting" : states.available_messages;
    const error_count = trends_status === "started" ? "deleting" : states.trends_batch_errors;

    const ids_with_errors = states.ids_with_errors || [];

    useEffect(() => {
        handleRefreshState();
    }, []);

    useEffect(() => {
        console.log(states);
        if (states.update_db !== update_db_status) {
            setUpdateDBStatus(states.update_db);
        }
        if (states.generate_trends !== trends_status) {
            setTrendsStatus(states.generate_trends);
        }
        if (states.available_messages > 0) {
            // set a timeout to clear the states
            clearTimeout(my_timeout);
        }
    }, [states]);

    useEffect(() => {
        if (loading_states) {
            let condition1 = READY_LIST.includes(update_db_status);
            let condition2 = READY_LIST.includes(trends_status);
            let condition3 = states.trends_batch_processed + states.trends_batch_errors >= states.trends_batch_size;
            if (condition1 && condition2 && condition3) {
                console.log(
                    "clearing auto refresh because update_db === finished && generate_trends === finished or pending"
                );
                clearTimeout(my_timeout);
                dispatch(clearStatesContinuoslyAPIAction());
            }
            // if (messages_on_queue === 0) {
            //     // set a timeout to clear the states
            //     my_timeout = setTimeout(() => {
            //         console.log("clearing auto refresh because messages_on_queue === 0");
            //         dispatch(clearStatesContinuoslyAPIAction());
            //     }, 5000);
            // } else {
            //     clearTimeout(my_timeout);
            // }
        }
    }, [update_db_status, trends_status, calling_get_state, states.trends_batch_processed, states.trends_batch_errors]);

    console.log("loading states: " + loading_states);
    console.log("calling_get_state: " + calling_get_state);

    return (
        <Div className="d-flex row col-lg-6 col-sm-12 p-3">
            <div className="d-flex justify-content-between title col">
                <div>
                    <h2>Current State</h2>
                </div>
                <div className="d-inline-flex align-items-center">
                    {!refreshing && (
                        <button
                            className="btn btn-outline-primary btn-sm me-2"
                            type="button"
                            onClick={handleRefreshState}
                        >
                            <i
                                className="fa-solid fa-arrows-rotate "
                                //   onClick={updateResolution}
                                title="Start Auto Refresh"
                                aria-label="Start Auto Refresh"
                            />
                        </button>
                    )}
                    {refreshing && (
                        <h2>
                            <div className="spinner-border text-primary" role="status">
                                <span style={{ backgroundColor: "#094e8f" }} className="visually-hidden">
                                    Loading...
                                </span>
                            </div>
                        </h2>
                    )}
                    {loading_states && trends_status !== "started" && update_db_status !== "started" && (
                        <button
                            className="btn btn-outline-primary btn-sm me-2"
                            type="button"
                            onClick={stopContinuousRefresh}
                        >
                            <i
                                className="fa-solid fa-stop "
                                //   onClick={updateResolution}
                                title="Stop Auto Refresh"
                                aria-label="Stop Auto Refresh"
                            />
                        </button>
                    )}
                </div>
            </div>

            <div className="d-flex mb-3 fields">
                <Field id="analysis_id" name="Analysis ID" value={ready ? states.analysis_id : "undefined"} />
            </div>
            {ready && (
                <div>
                    <h3>Database Update</h3>
                    <div className="d-flex mb-3 align-items-center">
                        <Button variant="primary" disabled={!update_db_ready} onClick={handleUpdateDB}>
                            Update DB
                        </Button>
                        {/* <span className="ms-3">Sends SQS message to lambda to read from Redshift and update DB</span> */}
                        <span className="ms-3">Updates Application Database from Redshift</span>
                    </div>

                    <div className="d-flex mb-3 fields">
                        <Field id="update_db" name="Update Status" value={update_db_status} />
                    </div>

                    <h3> Generate Trends</h3>
                    <div className="d-flex mb-3">
                        <Button variant="primary" disabled={!generate_trends_ready} onClick={handleGenerateTrends}>
                            Generate Trends
                        </Button>
                        <Button
                            className={"ms-3 "}
                            variant="primary"
                            disabled={!retry_trends_ready}
                            onClick={handleGenerateRemainingTrends}
                        >
                            Retry Unprocessed Trends
                        </Button>
                        <Button
                            className={"ms-3 "}
                            variant="danger"
                            aria-label="Delete all Messages"
                            disabled={!retry_trends_ready}
                            onClick={handleDeleteAllMessages}
                        >
                            Delete all Messages
                        </Button>
                    </div>
                    <div className="d-flex mb-3 fields row">
                        <Field id="generate_trends" name="Trend Generation Status" value={trends_status} />
                        {/* <Field id="trends_batch_id" name="Trends Batch ID" value={states.trends_batch_id} /> */}
                        <Field id="trends_batch_size" name="Number of Wells" value={states.trends_batch_size} />
                        <Field
                            id="trends_batch_processed"
                            name="Processed Wells"
                            value={states.trends_batch_processed}
                        />
                        <Field id="trends_batch_errors" name="Wells with errors" value={error_count} />
                        <Field id="messages_on_queue" name="Messages on queue" value={messages_on_queue} />
                        {/* <Field id="ids_with_errors" name="Merrick ids with errors" value={ids_with_errors} /> */}
                        <div className="form-group row mb-1 ids-with-errors">
                            <label htmlFor={"ids_with_errors"} className="col-sm-5 col-form-label">
                                {"List of ids with errors"}
                            </label>
                            <div className="col-sm-7">
                                {ids_with_errors.length > 0 ? (
                                    <ul>
                                        {ids_with_errors &&
                                            ids_with_errors.map((id) => {
                                                return <li>{id}</li>;
                                            })}
                                    </ul>
                                ) : (
                                    <ul className="p-1"
                                    style={{ minWidth: "200px", maxWidth: "300px", textAlign: "center"}}
                                    >None</ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Div>
    );
};

export default Stage2;

export const Field = ({ id, name, value }) => {
    if (value === undefined) {
        value = "undefined";
    }
    return (
        <div className="form-group row mb-1">
            <label htmlFor={id} className="col-sm-5 col-form-label">
                {name}
            </label>
            <div className="col-sm-7">
                <input type="text" readOnly 
                style={{ minWidth: "200px", maxWidth: "300px" }}
                className="form-control-plaintext text-center" id={id} value={value} />
            </div>
        </div>
    );
};
