import axios from "axios"
import { getApiDefaults } from "bkv-components/store_lib/actions/dataActions"

const alertError = (e) => {
    try {
        let output = e.response.data.detail
        alert(output)
    } catch {
        alert(e.message)
    }
}

export const fetchHeadersAPIAction = () => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        let parameters = {}

        try{
            var response = await axios.get(api_endpoint + "/admin/get_headers", {
                headers: headers,
                params: parameters,
            });
        } catch (error) {
            alertError(error)
            await dispatch({
                type: "LOAD_HEADERS",
                payload: [],
            });    
        }

        const data = response.data;
        await dispatch({
            type: "LOAD_HEADERS",
            payload: data,
        });
    }
}

export const fetchRawDataAPIAction = (merrick_id, start_date, end_date) => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        let parameters = {
            merrick_id: merrick_id,
            start_date: start_date,
            end_date: end_date,
        }

        try{
            var response = await axios.get(api_endpoint + "/admin/get_raw_data", {
                headers: headers,
                params: parameters,
            });
        } catch (error) {
            alertError(error)
            await dispatch({
                type: "LOAD_RAW_DATA",
                payload: undefined,
            });    
        }

        const data = response.data;
        await dispatch({
            type: "LOAD_RAW_DATA",
            payload: data,
        });
    }
}

export const fetchTrendDataAPIAction = (merrick_id, analysis_id) => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        let parameters = {
            merrick_id: merrick_id,
            analysis_id: analysis_id,
        }

        try{
            var response = await axios.get(api_endpoint + "/admin/get_trends_data", {
                headers: headers,
                params: parameters,
            });
        } catch (error) {
            alertError(error)
            await dispatch({
                type: "LOAD_RAW_DATA",
                payload: undefined,
            });    
        }

        const data = response.data;
        await dispatch({
            type: "LOAD_RAW_DATA",
            payload: data,
        });
    }
}


export const runDBMigrationAPIAction = () => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        try{
            var response = await axios.get(api_endpoint + "/admin/run_db_migration", {
                headers: headers,
            });
        } catch (error) {
            alertError(error)
        }

        // check if response is 200
        if (response.status !== 200) {
            alert("DB Migration Failed")
        } else {
            alert("DB Migration Complete")
        }

    }
}
