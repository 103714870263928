import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
// import myHtmlPage from 'bkv-components/html_pages/user_guide.html';

const Div = styled.div`
    /* .h_iframe iframe {
        width: 100%;
        height: 100%;
    }
    .h_iframe {
        height: 100%;
        width: 100%;
    } */


`;

const HelpModal = (props) => {
    const { show, onClose } = props;

    return (
        <Div>
            <Modal show={show} onHide={onClose} size="lg">
                {/* align center */}
                <Modal.Header style={{ justifyContent: "center" }}>
                    <Modal.Title style={{ textAlign: "right" }}>
                        <h2>User Guide</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{height: "700px"}}
                >
                        <iframe
                            src="user_guide.html"
                            title="User Guide"
                            frameborder="0"
                            // style="overflow:hidden;height:100%;width:100%"
                            style={{ overflow: "hidden", width: "100%", height: "100%" }}
                            // height="100%"
                            // width="780"
                            // height="700"
                        ></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Div>
    );
};

export default HelpModal;
