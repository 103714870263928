import Header from "bkv-components/Layout/Header";
import Layout from "bkv-components/Layout/Layout";
import React, { useEffect, useState } from "react";
import AdminQuery from "./AdminQuery";
import { Tab, Tabs } from "react-bootstrap";
import DataExploration from "./DataExploration";
import { Provider, useDispatch, useSelector } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "../gas_targets/store/reducers/rootReducer";
import thunk from "redux-thunk";
import { rdsCheckInstanceAPIAction } from "../gas_targets/store/actions/gasRdsActions";
import { DbMigration } from "./DbMigration";


const store = createStore(rootReducer, applyMiddleware(thunk));

function AdminDashboardApp() {

    const dispatch = useDispatch();

    //rds status
    const rds_status = useSelector((state) => state.rds.rds_status);
    const [status, setStatus] = useState(rds_status);

    const [error, setError] = React.useState(false);
    const [tab_key, setKey] = React.useState("log_query");

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    useEffect(() => {
        if (!rds_status) {
            dispatch(rdsCheckInstanceAPIAction());
        }

        setStatus(rds_status);
    }, [rds_status]);

    if (status !== "available") {
        return (
            <Layout>
                <Header title="Admin Dashboard" />
                <div className="container bg-light">
                    <div className="row">
                        <div className="col ">
                            <h3>RDS Status: {status}</h3>
                            <p>
                                Please make sure that the RDS instance is up and running.
                                </p>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }


    if (error) {
        return <div>Error:{error}</div>;
    }

    return (
        <Layout>
            <Header title="Admin Dashboard" />
                <Tabs
                    id="controlled-tab-example"
                    activeKey={tab_key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="log_query" title="Log Queries">
                        <AdminQuery />
                    </Tab>
                    <Tab eventKey="data_exploration" title="Data Exploration">
                        <DataExploration />
                    </Tab>
                    <Tab eventKey="db_migration" title="DB Migration">
                        <DbMigration />
                    </Tab>
                </Tabs>
        </Layout>
    );
}

const App = (props) => {
    return (
        <Provider store={store}>
            <AdminDashboardApp />
        </Provider>
    );
};

export default App;
