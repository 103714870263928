import React from 'react'
import { Button } from "react-bootstrap";
import { runDBMigrationAPIAction } from '../gas_targets/store/actions/adminDataActions'
import { useDispatch } from 'react-redux';

export const DbMigration = () => {

    const dispatch = useDispatch();

    const runDBMigration = () => {
        dispatch(runDBMigrationAPIAction());
    }

  return (
      <div>
          <Button onClick={() => runDBMigration()}>Run DB Migration</Button>
      </div>
  );
}
