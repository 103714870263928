// import fake_facility_data from "../../fake_data/facilities_1000.json";
// import fake_well_data from "../../fake_data/well_data_2.json";
import React from "react";
import { updateFiltersFromWellsData } from "./utilities";
import axios from "axios";
import { msalInstance } from "../../../index";
import { normalizeTime } from "bkv-components/SensorPlot/Components/utils";
import { loginRequest } from "../../../config/authConfig";
// import { InteractionRequiredAuthError } from "@azure/msal-browser";

const acquireAccessToken = async () => {
    /*
    Note: from https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
    It is best practice to attempt an acquireTokenSilent call before using the interactive APIs 
    if you have already logged in. This allows you to prevent unnecessary user interactions. 
    acquireTokenSilent will look for a valid token in the cache, and if it is close to expiring 
    or does not exist, will automatically try to refresh it for you. 

    If the acquireTokenSilent call attempts a refresh token call and the refresh token is expired, 
    MSAL will attempt to make a silent request in an iframe for a new authorization code. 
    If your session still exists, you will obtain a new authorization code silently, 
    which will be immediately traded for an access token.
    */

    // let scope = process.env.REACT_APP_SCOPE;

    let token = null;
    try {
        // getAllAccounts makes an http call that throws an exception
        const accounts = await msalInstance.getAllAccounts();
        const activeAccount = await msalInstance.getActiveAccount(); //not working?
        var tokenRequest = {
            account: accounts[0], // This is an example - Select account based on your app's requirements
            scopes: [window._env_.REACT_APP_SCOPE],
        };
        console.log("acquiring token");
        let res = await msalInstance.acquireTokenSilent(tokenRequest);
        token = res.accessToken;
    } catch (e) {
        console.log("acquireTokenFailure: interaction_required");
        // if (e instanceof InteractionRequiredAuthError) {
        // let res = await msalInstance.acquireTokenPopup(tokenRequest);
        console.log(e);
        try {
            let res = await msalInstance.acquireTokenPopup(tokenRequest);
            token = res.accessToken;
        } catch (e) {
            console.log("acquireTokenFailure: popup");
            console.log(e);
            throw e;
        }
    }

    return token;
};

export const getApiDefaults = async () => {
    // get authentication token
    try {
        var accessToken = await acquireAccessToken();
    } catch (e) {
        console.error("error getting access token", e);
        throw e;
    }

    // get full location path of document
    const location = window.location.href;
    //https://stackoverflow.com/a/5912481/9831182

    let headers = {
        accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "X-Referer": location,
    };

    // get data from static folder
    // window._env_.PUBLIC_URL + '/config.json'
    // try {
    //     let res = await axios.get(window._env_.PUBLIC_URL + "/config.json");
    //     var api_endpoint = res.data.api_endpoint;
    // } catch (e) {
    //     console.log("error getting api endpoint", e);
    //     api_endpoint = "";
    // }
    let api_endpoint = window._env_.REACT_APP_API_ENDPOINT;
    if (window._env_.REACT_APP_API_ENDPOINT === undefined) {
        console.error("REACT_APP_API_ENDPOINT not defined");
        api_endpoint = "";
    }

    return { api_endpoint, headers };
};

export const httpRequestErrorAction = (e,dispatch_code='HTTP_REQUEST_ERROR') => {
    return async (dispatch, getState) => {
        let status = e.response ? e.response.status : "";
        status = status.toString();
        if (status === "") {
            if (window.navigator.onLine) {
                status = "ServerOffline";
            } else {
                status = "NoInternet";
            }
        }
        return await dispatch({
            type: dispatch_code,
            payload: status,
        });
    };
};

export const downloadWellTable = () => {
    return async (dispatch, getState) => {
        // check if runnning production mode
        let data;
        {
            try {
                var { headers, api_endpoint } = await getApiDefaults();
            } catch (e) {
                return await dispatch({
                    type: "LOAD_WELL_DATA_ERROR",
                    payload: e.message,
                });
            }

            let parameters = {
                // max_rows : 5
            };
            try {
                var response = await axios.get(api_endpoint + "/wells", {
                    headers: headers,
                    params: parameters,
                });
            } catch (e) {
                return await dispatch(httpRequestErrorAction(e,'LOAD_WELL_DATA_ERROR'));
            }

            data = response.data;
        }

        // console.log(data);

        // sleep for 5 seconds (used for testing)
        // await new Promise(resolve => setTimeout(resolve, 5000));

        let well_data_hashed = {};
        for (let el of data) {
            let i = el.merrick_id;
            well_data_hashed[i] = el;
        }

        // update settings with new options
        // await dispatch({
        //     type: "CLEAR_SETTINGS",
        // });
        const { settings } = getState();
        const prev_filters = settings.filters;
        let filters = updateFiltersFromWellsData(data, prev_filters);

        await dispatch({
            type: "UPDATE_FILTERS",
            payload: filters,
        });

        await dispatch({
            type: "LOAD_WELL_DATA_HASHED",
            payload: well_data_hashed,
        });

        return await dispatch({
            type: "LOAD_WELL_DATA",
            payload: data,
        });
    };
};

export const clearComments = () => {
    return async (dispatch, getState) => {
        return await dispatch({
            type: "CLEAR_COMMENTS",
            payload: null,
        });
    };
};

export const clearSensorData = () => {
    return async (dispatch, getState) => {
        return await dispatch({
            type: "CLEAR_SENSOR_DATA",
            payload: null,
        });
    };
};

export const downloadSensorInfoAndData = (p2_scada_id) => {
    return async (dispatch, getState) => {
        //download sensor information to get date ranges
        try {
            const sensor_info = await dispatch(downloadSensorInfo(p2_scada_id));
            // if (sensor_info) {
            //     var start_time = normalizeTime(sensor_info.start_time); //returns a date
            //     var end_time = addDays(start_time, 1); //adds 1 day to start_time
            // }
        } catch (e) {
            await dispatch(httpRequestErrorAction(e));
        }
    };
};

export const downloadSensorInfo = (p2_scada_id) => {
    return async (dispatch, getState) => {
        // make api call to get sensor info
        const { api_endpoint, headers } = await getApiDefaults();
        try {
            var res1 = await axios.get(
                api_endpoint + "/sensors/info/" + p2_scada_id,
                { headers: headers }
            );
        } catch (e) {
            await dispatch(httpRequestErrorAction(e));
        }

        // load sensor info into redux store
        let sensor_info = res1.data;
        await dispatch({
            type: "LOAD_SENSOR_INFO",
            payload: sensor_info,
        });
        return sensor_info;
    };
};

export const downloadSensorInfoHashed = (p2_scada_id) => {
    return async (dispatch, getState) => {
        // make api call to get sensor info
        const { api_endpoint, headers } = await getApiDefaults();
        try {
            var res1 = await axios.get(
                api_endpoint + "/sensors/info/" + p2_scada_id,
                { headers: headers }
            );
        } catch (e) {
            await dispatch(httpRequestErrorAction(e));
        }

        // load sensor info into redux store
        let sensor_info = res1.data;
        await dispatch({
            type: "LOAD_SENSOR_INFO_HASHED",
            payload: sensor_info,
        });
        return sensor_info;
    };
};

export const downloadSensorData = (p2_scada_id, start_time, end_time) => {
    return async (dispatch, getState) => {
        const { api_endpoint, headers } = await getApiDefaults();

        let parameters = {};

        if (start_time && end_time) {
            //convert start_time to timestamp
            parameters.start_time = start_time.getTime();
            parameters.end_time = end_time.getTime();
        }

        try {
            var response = await axios.get(
                api_endpoint + "/sensors/data/" + p2_scada_id,
                { headers: headers, params: parameters }
            );
        } catch (e) {
            console.log(e);
            await dispatch(httpRequestErrorAction(e));
        }
        let data = response.data;

        await dispatch({
            type: "LOAD_SENSOR_DATA",
            payload: data,
        });
    };
};

export const downloadSensorMultiwellData = (
    p2_scada_id_list,
    sensor_id,
    start_time,
    end_time
) => {
    return async (dispatch, getState) => {
        const { api_endpoint, headers } = await getApiDefaults();

        // await dispatch({
        //     type: "CLEAR_SENSOR_DATA",
        // });
        // await dispatch({
        //     type: "CLEAR_COMMENTS",
        // });

        let parameters = {
            max_rows: 6000 * 20,
        };

        if (start_time && end_time) {
            //convert timestamp to ms
            parameters.start_time = start_time.getTime();
            parameters.end_time = end_time.getTime();
        }

        try {
            var response = await axios.post(
                api_endpoint + "/sensors/multiwell_data",
                {
                    p2_scada_id_list: p2_scada_id_list,
                    sensor_id: sensor_id,
                    // sensor_id: ['PCASE0','INSFLG0']
                },
                { headers: headers, params: parameters }
            );
            try {
                let data = response.data;
                data.start_time_parameter = parameters.start_time;
                data.end_time_parameter = parameters.end_time;

                await dispatch({
                    type: "LOAD_SENSOR_MULTIWELL_DATA",
                    payload: data,
                });
            } catch (e) {
                console.error(e);
            }
        } catch (e) {
            await dispatch(httpRequestErrorAction(e));
        }
    };
};

// export const checkApiConnectionAction = () => {
//     return async (dispatch, getState) => {
//         const { api_endpoint, headers } = await getApiDefaults();
//         try {
//             var res1 = await axios.get(api_endpoint + "/", {
//                 headers: headers,
//             });
//         } catch (e) {
//             let status = e.response ? e.response.status : "error";
//             dispatch({
//                 type: "SET_API_CONNECTION_ERROR",
//                 payload: status,
//             });

//         }
//         return dispatch({
//             type: "CLEAR_API_CONNECTION_ERROR",
//             payload: false,
//         });
//     };
// }
