
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { deleteAnalysisAPIAction, loadAnalysisListAPIAction, loadOverridesAPIAction } from '../../store/actions/gasDataActions';

const LoadAnalysisModal = (props) => {

    const {showModal, setShowModal, onChange} = props;

    const [selected_analysis, setSelectedAnalysis] = useState(undefined);

    const analysis_list = useSelector((state) => state.data.analysis_list);
    const dispatch = useDispatch();

    const loadAnalysis = () => {
        dispatch(loadOverridesAPIAction(selected_analysis));
        onChange(selected_analysis);
        setShowModal(false);
    }

    const onDelete = () => {
        if (selected_analysis === undefined) {
            alert("Please select an analysis to delete");
            return;
        }
        // display an alert to confirm deletion
        if (!window.confirm("Are you sure you want to delete analysis_id: " + selected_analysis + "?")) {
            return;
        }
        dispatch(deleteAnalysisAPIAction(selected_analysis));
        setShowModal(false);
    }
    
    useEffect(() => {
        dispatch(loadAnalysisListAPIAction());
    }, []);

    useEffect(() => {
        if (analysis_list.length > 0) {
            setSelectedAnalysis(analysis_list[0].analysis_id);
        }
    }, [analysis_list]);
        

  return (
    <Modal centered show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
        <Modal.Title>Load Analysis</Modal.Title>
    </Modal.Header>
    <Modal.Body>

        <div className='d-flex col'>
        <Form.Select
            value={selected_analysis}
            onChange={(e) => setSelectedAnalysis(e.target.value)}
        >
            {analysis_list
                .sort((a, b) => {
                    return a.analysis_id < b.analysis_id ? 1 : -1;
                })
                .map((el) => {
                return <option key={el.analysis_id} value={el.analysis_id}>{el.analysis_id}</option>
            })}
        </Form.Select>        
        <Button variant="danger"
                disabled={selected_analysis === undefined}
                onClick={() => onDelete()}>
            Delete
        </Button>
        </div>

    </Modal.Body>
    <Modal.Footer>
        {/* save button */}
        <Button variant="primary" 
        disabled={selected_analysis === undefined}
        onClick={() => loadAnalysis()}>
            Load
        </Button>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
        </Button>
    </Modal.Footer>
</Modal>
  )
}

export default LoadAnalysisModal