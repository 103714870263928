import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import styled from "styled-components";


const Div = styled.div`
// draw grid for table (for each cell)

.ExcelTable2007 {
    border: 1px solid #000;
    font-size: 11pt;
    font-family: Calibri;
    border-collapse: collapse;
}
.ExcelTable2007 th {
    border: 1px solid #000;
    padding: 5px 5px;
    background-color: #dedede;
    text-align: center;
}
.ExcelTable2007 td {
    border: 1px solid #000;
    padding: 5px 5px;
    text-align: left;
}
.ExcelTable2007 tr:nth-child(even) {
    background-color: #f6f6f6;
}

// set max width for each cell
.ExcelTable2007 td {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
 

button {
    margin-left: 0.25rem;
}

.controls {
    max-width: 600px;
}


`

const header_ids = ['merrick_id', 'code', 'override_volume','comments']

const ExcelFileHandler = (props) => {

    const {data:rows, onChange:setRows, onExport } = props;

    const fileInputRef = useRef(null);

    // const [dataLoaded, setDataLoaded] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(false);
    
    // replace column names with the header row
    const cols = header_ids.map((id,i) => {
        return {name: id, key: i}
    });

    const renderFile = (fileObj) => {
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {

                // check that the first row is the header row
                
                const header_row = resp.rows[0];
                let header_row_valid = true;
                header_ids.forEach((id) => {
                    if (!header_row.includes(id)) {
                        header_row_valid = false;
                    }
                });
                if (!header_row_valid) {
                    setIsFormInvalid(true);
                    alert("The first row of the spreadsheet must be the header row, and must contain the following column names: merrick_id, code, override_volume, comments");
                    return;
                }

                // remove the header row
                resp.rows.splice(0, 1);


                const merrick_id_col = header_row.indexOf("merrick_id");
                const code_col = header_row.indexOf("code");
                const override_volume_col = header_row.indexOf("override_volume");
                const comments_col = header_row.indexOf("comments");

                // remove rows where merrick_id is empty
                resp.rows = resp.rows.filter((row) => {
                    return row[merrick_id_col] !== "" && row[merrick_id_col] !== undefined;
                });


                // Check for duplicate merrick_id values
                const merrick_ids = resp.rows.map((row) => {
                    return row[merrick_id_col];
                });
                const merrick_ids_set = new Set(merrick_ids);
                if (merrick_ids.length !== merrick_ids_set.size) {
                    let repeated_ids = merrick_ids.filter((id, index) => {
                            return merrick_ids.indexOf(id) !== index;
                        }
                    );
                    repeated_ids = [...new Set(repeated_ids)];
                    setIsFormInvalid(true);
                    alert("The merrick_id column contains duplicate values. Repeated values: " + repeated_ids.join(", "));
                    return;
                }


                let overrides_endpoint_table = resp.rows.map((row) => {
                    return {
                        merrick_id: row[merrick_id_col],
                        code: row[code_col],
                        override_volume: row[override_volume_col],
                        comments: row[comments_col],
                    }
                });


                setRows(overrides_endpoint_table);

                // send data to parent
                // onChange(resp.rows);
            }
        });
    };

    const fileHandler = (event) => {
        if (event.target.files.length) {
            const fileObj = event.target.files[0];
            const fileName = fileObj.name;
            
            if (fileName.slice(fileName.lastIndexOf(".") + 1) === "xlsx") {
                // setDataLoaded(false);
                setIsFormInvalid(false);
                renderFile(fileObj);
            } else {
                setIsFormInvalid(true);
            }
        }
    };


    const onReset = () => {
        // setDataLoaded(false);
        fileInputRef.current.value = "";
        setIsFormInvalid(false);
        setRows([]);

    };






    const dataLoaded = rows.length > 0;

    return (
        <Div>
            <div>
                <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">
                        Reload Table from file:
                    </label>
                <div className="d-flex col controls">
                    <input 
                    className="form-control" type="file" id="formFile"
                    ref={fileInputRef}
                    onChange={fileHandler} 
                    />
                    <button className="btn btn-primary" 
                        disabled={!fileInputRef.current}
                        onClick={onReset}
                    >
                        Reset
                    </button>
                    {/* dont wrap button text */}
                    <button className="btn btn-primary flex-shrink-0"
                        disabled={!dataLoaded}
                        onClick={onExport}
                    >
                        Export to XLSX
                    </button>
                </div>
                    {isFormInvalid && <p>Please select a .xlsx file only !</p>}
                </div>

                {dataLoaded && (
                    <div>
                        <div>
                            <OutTable
                                withZeroColumn={false}
                                withoutRowNum={true}
                                data={rows}
                                columns={cols}
                                tableClassName="ExcelTable2007"
                                tableHeaderRowclassName="heading"
                            />
                        </div>
                    </div>
                )}
            </div>
        </Div>
    );
};

export default ExcelFileHandler;
