import settingsReducer from './settingsReducer'
import { combineReducers } from 'redux'
import gasDataReducer from './gasDataReducer';
import gasRdsStatus from './gasRdsStatus';
import adminDataReducer from './adminDataReducer';

const rootReducer = combineReducers({
  admin: adminDataReducer,
  rds: gasRdsStatus,
  settings: settingsReducer,
  data: gasDataReducer,
});

export default rootReducer

// the key name will be the data property on the state object