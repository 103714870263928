import React, { useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ExcelFileHandler from "./stage3/ExcelFileHandler";
import { setStage3StateAction, updateOverridesAPIAction } from "../store/actions/gasDataActions";
import NewAnalysisModal from "./stage1/NewAnalysisModal";
import LoadAnalysisModal from "./stage1/LoadAnalysisModal";
import OverridesTable from "./stage3/OverridesTable";



const Div = styled.div`

.title,
    h3 {
        border-bottom: 1px solid;
        margin-bottom: 2rem;
    }


form {
    margin-bottom: 2rem;
}

button {
    max-width: 80%;
}

.buttons button {
    margin-right: 1rem;
}

`




const Stage1 = () => {

    // const [start_date, setStartDate] = useState(new Date());
    // const [end_date, setEndDate] = useState(new Date());

    const [showAddModal, setShowAddModal] = useState(false);
    const [showLoadModal, setShowLoadModal] = useState(false);
    // const [overrides, setOverrides] = useState([]); // [merrick_id, code, override_volume, comments]

    // load dates from redux store
    const dispatch = useDispatch();
    const start_date = useSelector((state) => state.data.start_date);
    const end_date = useSelector((state) => state.data.end_date);
    const resetStates = () => dispatch({
        type: "LOAD_STATES",
        payload: [],
    });

    const dates_ready = start_date !== undefined && end_date !== undefined;

    const analysis_id = dates_ready? start_date+ "_" + end_date : "undefined";

    const setOverrides = (overrides) => dispatch({
        type: "LOAD_OVERRIDES",
        payload: overrides,
    });
    


    const setStartDate = (date) => {
        dispatch({
            type: "SET_START_DATE",
            payload: date,
        });
    };

    const setEndDate = (date) => {
        dispatch({
            type: "SET_END_DATE",
            payload: date,
        });
    };

    

    

    // const saveAnalysis = () => {
    //     if (!enable_save) {
    //         alert("Analysis cannot be saved until start_date, end_date, and override_table have been defined");
    //         return;
    //     }
    //     dispatch(updateOverridesAPIAction(analysis_id,overrides));  
    // }
        
    const handleLoadAnalysis = (analysis_id) => {
        // set start_date and end_date from analysis_id
        const [start_date, end_date] = analysis_id.split("_");
        setStartDate(start_date);
        setEndDate(end_date);
        dispatch(setStage3StateAction("none"));
        resetStates();

    }





        
    const handleNewAnalysis = (start_date, end_date) => {
            setStartDate(start_date);
            setEndDate(end_date);
            setOverrides([]); // clear overrides
            dispatch(updateOverridesAPIAction(analysis_id,[]));
            dispatch(setStage3StateAction("none"));
            resetStates();
        }



        

    return (
        <Div className="d-flex row col-lg-6 col-sm-12 p-3">
            <div className="title">
            <h2>Analysis</h2>
            </div>
            <div className="d-flex buttons">
                <Button
                    onClick={() => setShowAddModal(true)}
                    variant="primary">
                        New Analysis
                </Button>
                <Button
                    onClick={() => setShowLoadModal(true)}
                    variant="primary">
                        Load Analysis
                </Button>
                {/* <Button
                    onClick={() => saveAnalysis()}
                    disabled={!enable_save}
                    variant="primary">
                        Save Analysis
                </Button> */}
            </div>

            <NewAnalysisModal
                showModal={showAddModal}
                setShowModal={setShowAddModal}
                onChange={handleNewAnalysis}
            />

            {showLoadModal &&
            <LoadAnalysisModal
                showModal={showLoadModal}
                setShowModal={setShowLoadModal}
                onChange={handleLoadAnalysis}
                />
            }
                    

            

            <div className="mt-3 mb-3">
                <div className="form-group row">
                    <label htmlFor="start_date" className="col-sm-3 col-form-label">Start Date:</label>
                    <div className="col-sm-9">
                    <input type="text" readOnly className="form-control-plaintext" id="start_date" value={start_date?start_date:"undefined"} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="end_date" className="col-sm-3 col-form-label">End Date:</label>
                    <div className="col-sm-9">
                    <input type="text" readOnly className="form-control-plaintext" id="end_date" value={end_date?end_date:"undefined"} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="analysis_id" className="col-sm-3 col-form-label">Analysis Id:</label>
                    <div className="col-sm-9">
                    <input type="text" readOnly className="form-control-plaintext" id="analysis_id" value={analysis_id} />
                    </div>
                </div>

            </div>
{/* 
            <h3>Override Table</h3>

                <ExcelFileHandler 
                data={override_table}
                onChange={onTableChange}
                onExport={onExportToXLSX}
                /> */}

                {/* <OverridesTable/> */}
            


        </Div>
    );
};

export default Stage1;

