

export const updateFiltersFromWellsData = (raw_well_data, base_filters) => {
    /*
    * Creates filter options for each filter (e.g. adds names to p2_foreman filter)
    *
    * */

    // let new_settings = { ...settings };

    //reset filters
    // new_settings.filters = [...initState.filters] //uncomment this will break daily application/ dont use initState
    let new_filters = [...base_filters]



    // populate new_settings.filters
    for (let el of raw_well_data){
        for(let filter of new_filters){
            let key = filter.id; //example: p2_foreman
            //check if key is key in dictionary el
            if (el[key] !== undefined){
                if (filter.options.indexOf(el[key]) == -1){
                    filter.options.push(el[key])
                }
            }
        }
    }

    //sort filter options
    for (let filter of new_filters){
        filter.options.sort()
    }

    

    // new_settings.filters = filters;


    return new_filters;

}


