import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const NewAnalysisModal = (props) => {

    const {showModal, setShowModal, onChange} = props;

    const analysis_list = useSelector((state) => state.data.analysis_list);

    const [start_date, setStartDate] = useState(undefined);
    const [end_date, setEndDate] = useState(undefined);

    const saveDates = () => {

        //check analysis_id is unique
        let analysis_id = start_date + "_" + end_date;
        if (analysis_list.includes(analysis_id)) {
            alert("analysis_id:" + analysis_id + " already exists");
            return;
        }

        //check start_date and end_date are defined
        if (start_date === undefined || end_date === undefined) {
            alert("start_date and end_date must be defined");
            return;
        }

        //check end_date > start_date
        if (start_date > end_date) {
            alert("start_date must be before end_date");
            return;
        }

        onChange(start_date, end_date);
        setShowModal(false);

    }
    return(

        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>New Analysis</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                <Form.Group controlId="start_date">
                    <Form.Label>Select start_date</Form.Label>
                    <Form.Control 
                        value={start_date}
                        onChange={(e) => setStartDate(e.target.value)}
                        type="date" 
                        name="start_date" 
                        placeholder="start_date"
                         />
                </Form.Group>
                <br />
                <Form.Group controlId="end_date">
                    <Form.Label>Select end_date</Form.Label>
                    <Form.Control 
                        value={end_date}
                        onChange={(e) => setEndDate(e.target.value)}
                        type="date" 
                        name="end_date" 
                        placeholder="end_date" />
                </Form.Group>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* save button */}
                    <Button variant="primary" onClick={() => saveDates()}>
                        Set
                    </Button>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
    )
}

export default NewAnalysisModal;

