export const default_user_plot_settings = {
    log_INSFLG0: false,
    merged_pressure_plots: false,
    hide_comments: false,
};

export const getAxisFromUDC = (label,i=1) => {
    const label_vs_axis = {
        INSFLG0: 1,
        CMLINE0: 2,
        PTUBE0: 3,
        PCASE0: 4,
    }
    if (label in label_vs_axis) {
        return label_vs_axis[label];
    } else {
        return i;
    }
}

const colors = [
    '#ff7f0e',  // orange // CMLINE0
    '#9467bd',  // purple // PTUBE0
    '#17becf',  // cyan // PCASE0
    '#8c564b',  // brown // INSFLG1
    '#e377c2',  // pink
    '#7f7f7f',  // grey
    '#bcbd22',  // yellow
    '#d62728',  // brick red //INSFLG0
    '#1f77b4',  // blue
    '#2ca02c',  // green
]

export const getColorFromUDC = (label,i=0) => {
    const label_vs_color = {
        INSFLG0: '#d62728', // brick red
        CMLINE0: '#ff7f0e', // orange
        PTUBE0: '#9467bd', // purple
        PCASE0: '#17becf', // cyan
        LOADFACTOR: '#8c564b', // brown
        gas_production: '#d62728', // brick red
        oil_production: 'green', // green
        water_production: 'blue', // blue
        casing_pressure: 'gray', // gray
        tubing_pressure: 'black', // black
        line_pressure: 'gold', // orange
        gas_target: 'pink', // pink
        gas_budget: 'purple', // purple
        gas_forecast: 'orange', // orange
        gasbuyback_whp: 'cyan', // cyan

    }
    if (label in label_vs_color) {
        return label_vs_color[label];
        // return colors[label_vs_color[label]];
    } else {
        return null;
        // return colors[i];
    }
}





export const normalizeTime = (time) => {
    /*
    inputs an integer and returns a date object
    */
    // sample record_time = 1651363212000000000; (19 digits)
    // console.log('number of digits in time: ', time.toString().length)
    let newtime = time;
    // check if time is in nanoseconds
    if (time.toString().length === 19 || time.toString().length === 18) {
        newtime = time / 1000000;
    } else if (time.toString().length === 13 || time.toString().length === 12) {
        //do nothing
    } else if (time.toString().length > 13) {
        //backwards compatibility
        newtime = time.toString().substring(0, 13);
    } else if (typeof newtime === "string") {
        // check if date is in string format (ISO 8601)
        const dateParsed = new Date(Date.parse(newtime))

        if(!dateParsed.toUTCString() === new Date(newtime).toUTCString()){
            throw new Error("time is not in the correct format");
        }else{
            return newtime;
        }
    } else {
        throw new Error("time is not in the correct format");
    }


    if (typeof newtime === "string") {
        newtime = parseInt(newtime);
    }

    newtime = new Date(newtime);
    return newtime;

    // return time //"1651363212000"
};



export const sortDateString = (a, b, aRow, bRow, column, dir, sorterParams) => {
    //a, b - the two values being compared
    //aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
    //column - the column component for the column being sorted
    //dir - the direction of the sort ("asc" or "desc")
    //sorterParams - sorterParams object from column definition array

    let new_a = new Date(a);
    let new_b = new Date(b);

    let timediff = new_a.getTime() - new_b.getTime();

    return timediff;
};



export const getAxisNames = (trace_info) => {
    let axis_names = [];
    for (let key in trace_info) {
        let axis_name = trace_info[key].yaxis_name;
        if (!axis_names.includes(axis_name)) {
            axis_names.push(axis_name);
        }
    }
    return axis_names;
};

export const getAxisUnits = (trace_info) => {
    let axis_units = [];
    let axis_names = getAxisNames(trace_info);
    for (let axis_name of axis_names) {
        let trace_key = Object.keys(trace_info).find((key) => {
            return trace_info[key].yaxis_name === axis_name;
        });
        axis_units.push(trace_info[trace_key].unit);
    }
    
    return axis_units;
};

export const getAxisColors = (trace_info) => {
    let axis_colors = [];
    let used_names = [];
    for (let key in trace_info) {
        let axis_name = trace_info[key].yaxis_name;
        if (!used_names.includes(axis_name)) {
            used_names.push(axis_name);
            axis_colors.push(trace_info[key].color);
        }
        else {
            let index = used_names.indexOf(axis_name);
            axis_colors[index] = undefined;
        }
        
    }
    return axis_colors;
};


export const getTraceAxis = (trace_info, p2_scada_id, sensor_id) => {
    /*
    * Sample return: 'y2'
    */
   //find element in trace_info with p2_scada_id and sensor_id
    let trace_key = Object.keys(trace_info).find((key) => {
        return (
            trace_info[key].well_id === p2_scada_id &&
            trace_info[key].sensor_id === sensor_id
        );
    });
    if (trace_key === undefined) {
        return undefined;
    }
    const yaxis_name = trace_info[trace_key].yaxis_name;
    const axis_names = getAxisNames(trace_info);
    const axis_index = axis_names.indexOf(yaxis_name);
    return `y${axis_index + 1}`;

};

export const getAxisInfoFromTraceInfo = (trace_info) => {
    let axis_info = {};
    let axis_names = getAxisNames(trace_info);
    let axis_units = getAxisUnits(trace_info);
    // let axis_colors = getAxisColors(trace_info);
    let domain_height = 1 / axis_names.length;
    for (let i = 0; i < axis_names.length; i++) {
        axis_info[axis_names[i]] = {
            unit: axis_units[i],
            color: undefined,//axis_colors[i],
            domain_height: domain_height,
            // domain: [domain_height * i, domain_height * (i + 1)],
        };
    }
    return axis_info;
};

export const getAxisDomain = (axis_name, axis_info,sep) => {
    // domain: [sep + (1 / n_axis) * (j-1), (1 / n_axis) * (j)]
    let n_axis = Object.keys(axis_info).length;
    let j = Object.keys(axis_info).indexOf(axis_name) + 1;
    let axis_names = Object.keys(axis_info);
    // let axis_index = axis_names.indexOf(axis_name);
    let domain_height = axis_info[axis_name].domain_height;
    let is_last = j === n_axis;
    let is_first = j === 1;
    let first_sep = is_first ? 0 : sep/2;
    let last_sep = is_last ? 0 : sep/2;
    let prev_axis_pointer = is_first ? 0 : 
        //SUM of all previous axis domain_heights
        axis_names.slice(0, j-1).reduce((acc, axis_name) => {
            return acc + axis_info[axis_name].domain_height;
        }, 0);

        

    return [prev_axis_pointer+first_sep, prev_axis_pointer+ domain_height - last_sep];
};

const isArrayEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}

export const getTraceInfoFromAxisInfoAndTraceInfo = (axis_info, trace_info) => {
            let trace_info_helper = trace_info;
            //check if axis_info is valid
            let axis_info_from_trace_info = getAxisInfoFromTraceInfo(trace_info);
            let axis_info_from_trace_info_keys = Object.keys(axis_info_from_trace_info);
            // make sure keys are unique
            if (axis_info_from_trace_info_keys.length !== new Set(axis_info_from_trace_info_keys).size) {
                console.error('axis_info is not valid, keys are not unique');
                // throw new Error('axis_info is not valid, keys are not unique');
            }
            let axis_info_keys = Object.keys(axis_info);
            if (!isArrayEqual(axis_info_from_trace_info_keys, axis_info_keys)) {
                // make sure difference is only in order and sort trace_info_helper if so
                let arr1_sorted = axis_info_from_trace_info_keys.sort();
                let arr2_sorted = [...axis_info_keys].sort();
                if (!isArrayEqual(arr1_sorted, arr2_sorted)) {
                    console.error('axis_info is not valid');
                    // throw new Error('axis_info is not valid');
                } else {
                    // modify trace_info order to match axis_info order
                    console.log('trace_info will be modified to match axis_info order');
                    trace_info_helper = {};
                    const sorted_keys = Object.keys(trace_info).sort((a,b) => {
                        let yaxis_name_a = trace_info[a].yaxis_name;
                        let yaxis_name_b = trace_info[b].yaxis_name;
                        return axis_info_keys.indexOf(yaxis_name_a) - axis_info_keys.indexOf(yaxis_name_b);
                    });
                    sorted_keys.forEach((key) => {
                        trace_info_helper[key] = trace_info[key];
                    });
                }
            } 

            // verification check (only if all keys are present)
            // if (!isArrayEqual(Object.keys(trace_info_helper), Object.keys(axis_info_helper))) {
            //     //ERROR IN THE CODE ABOVE
            //     throw new Error('trace_info_helper and axis_info_helper are not valid');
            // }

            return trace_info_helper;
        };
        
