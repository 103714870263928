// import "./styles/index.css";
// import "./styles/plots.css";
// import "./styles/checkboxmenu.css";
// import "./styles/index_2.css"

import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Provider, useDispatch, useSelector } from "react-redux";

import { createStore, applyMiddleware } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import thunk from "redux-thunk";
import Header from "bkv-components/Layout/Header";
import Layout from "bkv-components/Layout/Layout";
import MainTabs from "./MainTabs";
// const store = createStore(rootReducer);
const store = createStore(rootReducer, applyMiddleware(thunk));

const MainBody = styled.div`
    /* min-height 100vh - height 105.062px */

    min-height: calc(100vh - 105.062px);
`;

const GasTargets = (props) => {
    const dispatch = useDispatch();


    const user_plot_settings = useSelector(
        (state) => state.settings.user_plot_settings
    );

    const [show_settings, setShowSettings] = useState(true);
  

    const plot_mounted = useSelector((state) => state.settings.plot_mounted);

    const clearSettings = async () => {
        await dispatch({
            type: "CLEAR_SENSOR_DATA",
        });
        await dispatch({
            type: "CLEAR_SETTINGS",
        });
        await dispatch({
            type: "CLEAR_COMMENTS",
        });
    };


    return (
        <Layout>
            <Header title="Gas Targets Application" />
                    <MainTabs/>
                    {/* <InnerAppLayout
                        leftPane={
                            <LeftPane/>
                        }
                        rightPane={
                            <RightPane/>
                        }
                        wells_loaded={well_loaded}
                        well_data_error={well_data_error}
                        plot_mounted={plot_mounted}
                        show_settings={show_settings}
                        setShowSettings={setShowSettings}
                        clearSettings={clearSettings}
                    /> */}
        </Layout>
    );
};

const App = (props) => {
    return (
        <Provider store={store}>
            <GasTargets />
        </Provider>
    );
};

export default App;

