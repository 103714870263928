import { getApiDefaults, httpRequestErrorAction } from "bkv-components/store_lib/actions/dataActions";
import axios from "axios";
import { updateFiltersFromWellsData } from "bkv-components/store_lib/actions/utilities";
import {settingsGTInitState} from "../reducers/settingsReducer"


// LOCAL ACTIONS

const alertError = (e) => {
    try {
        let output = e.response.data.detail
        alert(output)
    } catch {
        alert(e.message)
    }
}

export const setStage3StateAction = (stage3_state) => {
    const states = ['none','case1','case2']
    if (!states.includes(stage3_state)) {
        throw new Error("Invalid stage3_state: " + stage3_state);
    }
    return async (dispatch, getState) => {
        dispatch({
        type: "SET_STAGE3_STATE",
        payload: stage3_state,
        });
    };
};

export const setLoadingStatesAction = (loading_states) => {
    return async (dispatch, getState) => {
        dispatch({
            type: "SET_LOADING_STATES",
            payload: loading_states,
        });
    };
};




export const downloadCase2DataAPIAction = (start_date, end_date) => {
    return async (dispatch, getState) => {
        return dispatch(downloadCase1DataAPIAction(start_date, end_date,"/special_cases_2"));
    };
};
        

export const downloadCase1DataAPIAction = (start_date, end_date,endpoint="/special_cases_1") => {
    return async (dispatch, getState) => {
        // check if runnning production mode
        let data;
        {
            await dispatch({
                type: "LOAD_CASES_1_ERROR",
                payload: undefined
            });
            
            let parameters = {
                start_date: start_date,
                end_date: end_date,
            };
            try {
                var { headers, api_endpoint } = await getApiDefaults();
                var response = await axios.get(api_endpoint + endpoint, {
                    headers: headers,
                    params: parameters,
                });
            } catch (e) {
                await dispatch({
                    type: "LOAD_CASES_1_HEADERS",
                    payload: [],
                })
                return await dispatch({
                    type: "LOAD_CASES_1_ERROR",
                    payload: e.message,
                });
            }

            data = response.data;
        }

    
        // const { settings } = getState();
        const prev_filters = {...JSON.parse(JSON.stringify(settingsGTInitState))}.filters;
        let filters = updateFiltersFromWellsData(data.headers, prev_filters);

        await dispatch({
            type: "UPDATE_FILTERS",
            payload: filters,
        });

        await dispatch({
            type: "LOAD_CASES_1_HEADERS",
            payload: data.headers,
        });

        return await dispatch({
            type: "LOAD_CASES_1_DATA",
            payload: data.data,
        });
    };
};


export const updateOverridesAPIAction = (analysis_id,overrides) => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();
        // const { settings } = getState();



        try {
            var response = await axios.post(api_endpoint + "/overrides/update_overrides",
                {
                    analysis_id: analysis_id,
                    overrides: overrides,
                },
                { headers: headers }
            );

            alert("Analysis overrides saved");

        } catch (e) {
            alertError(e)
        } 
    };
};

export const loadOverridesAPIAction = (analysis_id) => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();
        // const { settings } = getState();

        try {
            var response = await axios.get(api_endpoint + "/overrides/get_overrides",
                {
                    params: {
                        analysis_id: analysis_id,
                    },
                    headers: headers
                }
            );

            return await dispatch({
                type: "LOAD_OVERRIDES",
                payload: response.data,
            });

        } catch (e) {
            alertError(e)
        }
    };
}


export const loadAnalysisListAPIAction = () => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        try {
            var response = await axios.get(api_endpoint + "/analysis/get_analysis_list",
                { headers: headers }
            );

            return await dispatch({
                type: "LOAD_ANALYSIS_LIST",
                payload: response.data,
            });

        } catch (e) {
            alertError(e)
        } 
    };
}

export const deleteAnalysisAPIAction = (analysis_id) => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        try {
            var response = await axios.post(api_endpoint + "/analysis/delete_analysis",
                {
                    analysis_id: analysis_id,
                },
                { headers: headers }
            );

            return await dispatch({
                type: "LOAD_ANALYSIS_LIST",
                payload: response.data,
            });

        } catch (e) {
            alertError(e)
        }
    };
}

const setCallingGetStateAction = (calling_get_state) => {
    return async (dispatch, getState) => {
        await dispatch({
            type: "SET_CALLING_GET_STATE",
            payload: calling_get_state,
        });
    };
};

            



export const getStatesAPIAction = (analysis_id) => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        let parameters = {
            analysis_id: analysis_id,
        };

        try {
            await dispatch(setCallingGetStateAction(true));
            var response = await axios.get(api_endpoint + "/get_state",
                { headers: headers, params: parameters }
            );
            
            await dispatch({
                type: "LOAD_STATES",
                payload: response.data,
            });

            
        } catch (e) {
            alertError(e)
        } finally {
            await dispatch(setCallingGetStateAction(false));    
        }
    };
}



export const sqsUpdateDBAPIAction = (start_date,end_date) => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        let parameters = {
            start_date: start_date,
            end_date: end_date,
        };

        try {
            var response = await axios.get(api_endpoint + "/sqs/update_db",
                { headers: headers, params: parameters }
            );
        } catch (e) {
            alertError(e)
        } 
        };
    }

export const sqsGenerateTrendsAPIAction = (start_date,end_date) => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        let parameters = {
            start_date: start_date,
            end_date: end_date,
        };

        try {
            var response = await axios.get(api_endpoint + "/sqs/generate_trends",
                { headers: headers, params: parameters }
            );
        } catch (e) {
            alertError(e)
        }
    };
}

export const sqsGenerateRemainingTrendsAPIAction = (start_date,end_date) => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        let parameters = {
            start_date: start_date,
            end_date: end_date,
        };

        try {
            var response = await axios.get(api_endpoint + "/sqs/generate_remaining_trends",
                { headers: headers, params: parameters }
            );
        } catch (e) {
            alertError(e)
        }
    };
}

export const sqsDeleteAllMessagesAPIAction = () => {
    return async (dispatch, getState) => {
        const { headers, api_endpoint } = await getApiDefaults();

        try {
            var response = await axios.get(api_endpoint + "/sqs/delete_all_messages",
                { headers: headers }
            );
            alert("All messages deleted");
        } catch (e) {
            alertError(e)
        }
    }
}





let interval = null;

export const getStatesContinuoslyAPIAction = (analysis_id,timeout=5) => {
    return async (dispatch, getState) => {

        let elapsed_time = 0;
        let initial_time = new Date().getTime();

        let call_in_progress = false;

        clearInterval(interval);
        await dispatch(setLoadingStatesAction(true));

        interval = setInterval(async () => {

            if (!call_in_progress) {
                call_in_progress = true;
                await dispatch(getStatesAPIAction(analysis_id));
                call_in_progress = false;
            }


            let current_time = new Date().getTime();

            elapsed_time = current_time - initial_time;
            console.log("elapsed_time: " + elapsed_time)

            if (elapsed_time > timeout*1000) {
                console.log("clearing interval due to timeout")
                clearInterval(interval);
                await dispatch(setLoadingStatesAction(false));
            }

        }, 2000);
    }
}

export const clearStatesContinuoslyAPIAction = () => {
    return async (dispatch, getState) => {
        clearInterval(interval);
        await dispatch(setLoadingStatesAction(false));
    }
}





// UNUSED
// export const downloadGasTargetOutputAPIAction = (analysis_id,start_date_output, end_date_output) => {
//     return async (dispatch, getState) => {
//         const { headers, api_endpoint } = await getApiDefaults();

//         let parameters = {
//             analysis_id: analysis_id,
//             start_date_output: start_date_output,
//             end_date_output: end_date_output,
//         };

//         try {
//             var response = await axios.get(api_endpoint + "/download_final_target_output",
//                 { headers: headers, 
//                     responseType: 'blob',
//                     params: parameters }
//             );

//             // Create a blob object from the response data
//             const blob = new Blob([response.data], { type: 'text/csv' });

//             return await dispatch({
//                 type: "SET_FINAL_TARGET_OUTPUT_CSV",
//                 payload: blob,
//             });
//         } catch (e) {
//             alert(e.message)
//         }
//     };
// }