import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchHeadersAPIAction, fetchRawDataAPIAction, fetchTrendDataAPIAction } from "../gas_targets/store/actions/adminDataActions";
import FacilitySelectBase from "bkv-components/FacilitySelection/FacilitySelectBase";
import styled from "styled-components";
import GasTargetsPlots from "../gas_targets/components/plots/GasTargetsPlots";
import LoadAnalysisModal from "../gas_targets/stages/stage1/LoadAnalysisModal";
import { Field } from "../gas_targets/stages/Stage2";

const Div = styled.div`
    .main-buttons button {
        margin-right: 1rem;
    }
    .fields input {
        width: 250px;
        background-color: #f8f9fa;
        text-align: center;
    }
`;

const MainBody = styled.div`
    /* min-height 100vh - height 105.062px */

    min-height: calc(100vh - 105.062px);
`;

const DataExploration = () => {
    const raw_data = useSelector((state) => state.admin.raw_data);
    const start_date = useSelector((state) => state.data.start_date);
    const end_date = useSelector((state) => state.data.end_date);
    const analysis_id = start_date + "_" + end_date;

    const [type, setType] = React.useState("raw");
    const [selection, setSelection] = React.useState([]);
    const [well_header, setWellHeader] = React.useState({});

    const headers = useSelector((state) => state.admin.headers);
    const dispatch = useDispatch();


    const onSelectionChange = (new_selection) => {
        const merrick_id = new_selection[0];
        let well_header = headers.filter((el) => el.merrick_id === merrick_id)[0];
        setWellHeader(well_header);
        console.log(new_selection);
        setSelection(new_selection);
        if (type === "raw"){
            dispatch(fetchRawDataAPIAction(merrick_id, start_date, end_date));
        } else if (type === "trends"){
            dispatch(fetchTrendDataAPIAction(merrick_id, analysis_id));
        }
    };

    useEffect(() => {
        //load headers
        dispatch(fetchHeadersAPIAction());
    }, []);

    //ontype change
    useEffect(() => {
        if (selection.length > 0) {
            const merrick_id = selection[0];
            if (type === "raw") {
                dispatch(fetchRawDataAPIAction(merrick_id, start_date, end_date));
            } else if (type === "trends") {
                dispatch(fetchTrendDataAPIAction(merrick_id, analysis_id));
            }
        }
    }, [type]);

    console.log(raw_data);

    

    return (
        <Div>
            {/* <div className="title">
                <h2>Admin Queries</h2>
            </div> */}

            <LoadAnalysis />

            <div className="d-flex mb-3 mt-3 fields">
                <Field id="analysis_id" name="Analysis ID" value={analysis_id} />
            </div>
            {!start_date || !end_date ? (
                <div>
                    {/* message to select start and end date */}
                    <h4>Select start and end date</h4>
                </div>
            ) : (
                headers.length > 0 && (
                    <MainBody className="d-flex flex-wrap flex-md-nowrap justify-content-center">
                        <div className="maincontainer col-12 col-md-4 col-lg-6 col-xl-5 col-xxl-4 p-3">
                            <FacilitySelectBase
                                title={"Well Selection"}
                                selection={selection}
                                data={headers}
                                onSelectionChange={onSelectionChange}
                                disabled={false}
                                selector_type={"radio"}
                                // onSwitchPane={onSwitchPane}
                            />
                        </div>

                        <div className={"maincontainer p-3 col-12 col-md-8 col-lg-6 col-xl-7 col-xxl-8"}>
                            <div className="type-selection d-flex col">
                                <div className="col-3">
                                    <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
                                        <option value="raw">raw table</option>
                                        <option value="trends">trends table</option>
                                    </Form.Select>
                                </div>
                            </div>
                            {}

                            {raw_data &&
                                (raw_data.length > 0 ? (
                                    <GasTargetsPlots well_header={well_header} data={raw_data} onAfterPlot={() => {}} />
                                ) : (
                                    <div className="element p-4">
                                        <h4>No data for this well</h4>
                                    </div>
                                ))}
                        </div>
                    </MainBody>
                )
            )}
            <div className="facility-selection"></div>
        </Div>
    );
};

export default DataExploration;

const LoadAnalysis = (props) => {
    const [showLoadModal, setShowLoadModal] = useState(false);
    const dispatch = useDispatch();

    const setStartDate = (date) => {
        dispatch({
            type: "SET_START_DATE",
            payload: date,
        });
    };

    const setEndDate = (date) => {
        dispatch({
            type: "SET_END_DATE",
            payload: date,
        });
    };

    const handleLoadAnalysis = (analysis_id) => {
        // set start_date and end_date from analysis_id
        const [start_date, end_date] = analysis_id.split("_");
        setStartDate(start_date);
        setEndDate(end_date);
    };
    return (
        <div>
            <Button onClick={() => setShowLoadModal(true)} variant="primary">
                Load Analysis
            </Button>
            {showLoadModal && (
                <LoadAnalysisModal
                    showModal={showLoadModal}
                    setShowModal={setShowLoadModal}
                    onChange={handleLoadAnalysis}
                />
            )}
        </div>
    );
};
