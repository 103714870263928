import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Field } from "./Stage2";
import { useDispatch, useSelector } from "react-redux";
import {
    clearRdsCheckContinuoslyAPIAction,
    rdsCheckContinuoslyAPIAction,
    rdsCheckInstanceAPIAction,
    rdsStartInstanceAPIAction,
    rdsStopInstanceAPIAction,
} from "../store/actions/gasRdsActions";
import { sqsDeleteAllMessagesAPIAction } from "../store/actions/gasDataActions";

const Div = styled.div`
    .title,
    h3 {
        border-bottom: 1px solid;
        margin-bottom: 2rem;
    }

    form {
        margin-bottom: 2rem;
    }

    button {
        max-width: 80%;
    }

    .buttons button {
        margin-right: 1rem;
    }

        

    .fields input {
        background-color: #e9ecef;
        text-align: center;
    }
`;

function Stage0() {
    const rds_status = useSelector((state) => state.rds.rds_status);
    const checking_status = useSelector((state) => state.rds.checking_status);
    const rds_refreshing = useSelector((state) => state.rds.rds_refreshing);

    console.log('rds_refreshing', rds_refreshing);


    const states = useSelector((state) => state.data.states);


    const [status, setStatus] = useState(rds_status);
    const dispatch = useDispatch();

    const checkInstance = () => {
        dispatch(rdsCheckInstanceAPIAction());
    };

    const startInstance = async () => {
        setStatus("starting_local");
        await dispatch(rdsStartInstanceAPIAction());
        await dispatch(rdsCheckContinuoslyAPIAction());
    };

    const stopInstance = async () => {
        if (states.available_messages > 0) {
            // Ask if user wants to delete all messages
            const confirm = window.confirm(
                "Can not stop while there are messages in the queue. Do you want to delete all messages?"
            );
            if (confirm) {
                // Delete all messages
                await dispatch(sqsDeleteAllMessagesAPIAction());
            } else {
                return;
            }
        }
        setStatus("stopping_local");
        await dispatch(rdsStopInstanceAPIAction());
        await dispatch(rdsCheckContinuoslyAPIAction());
    };

    const rebootInstance = async () => {
        setStatus("rebooting_local");
        await dispatch(rdsStopInstanceAPIAction());
        await dispatch(rdsCheckContinuoslyAPIAction());
    };

    const handleCheckInstance = () => {
        dispatch(rdsCheckInstanceAPIAction());
    };

    const stopContinuousRefresh = () => {
        dispatch(clearRdsCheckContinuoslyAPIAction());
    };

    const startContinuousRefresh = () => {
        dispatch(rdsCheckContinuoslyAPIAction());
    }


    useEffect(() => {
        if (!rds_status) {
            checkInstance();
        }

        setStatus(rds_status);
    }, [rds_status]);

    useEffect(() => {
        if (['available', 'stopped'].includes(status)) {
            stopContinuousRefresh();
        }
    }, [status, checking_status]);

    const refreshing = checking_status || rds_refreshing;

    return (
        <Div className="d-flex row col-lg-6 col-sm-12 p-3">
            <div className="d-flex justify-content-between title col">
                <div>
                    <h2>Application Database</h2>
                </div>
                <div className="d-inline-flex align-items-center">
                    {!refreshing && (
                        <button
                            className="btn btn-outline-primary btn-sm me-2"
                            type="button"
                            onClick={startContinuousRefresh}
                        >
                            <i
                                className="fa-solid fa-arrows-rotate "
                                //   onClick={updateResolution}
                                title="Refresh"
                                aria-label="Refresh"
                            />
                        </button>
                    )}
                    {refreshing && (
                        <h2>
                            <div className="spinner-border text-primary" role="status">
                                <span style={{ backgroundColor: "#094e8f" }} className="visually-hidden">
                                    Loading...
                                </span>
                            </div>
                        </h2>
                    )}
                    {rds_refreshing &&
                    (
                         <button
                         className="btn btn-outline-primary btn-sm me-2"
                         type="button"
                         onClick={stopContinuousRefresh}
                     >
                         <i
                             className="fa-solid fa-stop "
                             title="Stop Auto Refresh"
                             aria-label="Stop Auto Refresh"
                         />
                     </button>
                    )
                    }
                </div>
            </div>

            <div className="d-flex buttons mb-3">
                <Button onClick={() => startInstance()} variant="primary" disabled={status !== "stopped"}>
                    Start Database
                </Button>
                <Button disabled={status !== "available"} 
                    onClick={() => stopInstance()} variant="primary">
                    Stop Database
                </Button>
                <Button 
                    className="d-none"
                    disabled={status !== "available"} 
                    onClick={() => rebootInstance()} variant="primary">
                    Reboot Instance
                </Button>
                <Button 
                className="d-none"
                onClick={() => checkInstance()} 
                variant="primary">
                    Check Instance
                </Button>
                <Button 
                className="d-none"
                onClick={() => stopContinuousRefresh()} variant="primary">
                    Clear Refresh
                </Button>
            </div>

            <div className="d-flex mb-3 mt-3 fields">
                <Field id="rds_status" name="DB Status" value={status} />
            </div>
        </Div>
    );
}

export default Stage0;
