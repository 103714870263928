/*
 * this component must have a query input bar with an execute button
 * this component must have a table that displays the results of the query
 */

import axios from "axios";
import { getApiDefaults } from "bkv-components/store_lib/actions/dataActions";
import React, { useEffect } from "react";
import { ReactTabulator } from "react-tabulator";
import styled from "styled-components";

const Div = styled.div`
    .tabulator,
    .tabulator-header,
    .tabulator-tableHolder {
        overflow: visible !important;
    }
`;

const log_query_data_example = [
    {
        log_time: "2022-09-19 11:29:02.983513",
        log_name: "test",
        log_level: "info",
        log_msg: "test",
        name: "test",
        unique_name: "Jane",
        ipaddr: "test",
        endpoint: "test",
        params: "test",
        application: "admin",
    },
];

const base_columns = [
    { title: "log_time", field: "log_time", minWidth: 100, maxWidth: 200, tooltip: true },
    { title: "log_name", field: "log_name", minWidth: 100, maxWidth: 200, tooltip: true },
    { title: "log_level", field: "log_level", minWidth: 100, maxWidth: 200, tooltip: true },
    { title: "log_msg", field: "log_msg", minWidth: 100, maxWidth: 200, tooltip: true },
    { title: "name", field: "name", minWidth: 100, maxWidth: 200, tooltip: true },
    { title: "unique_name", field: "unique_name", minWidth: 100, maxWidth: 200, tooltip: true },
    { title: "ipaddr", field: "ipaddr", minWidth: 100, maxWidth: 200, tooltip: true },
    { title: "endpoint", field: "endpoint", minWidth: 100, maxWidth: 200, tooltip: true },
    { title: "params", field: "params", minWidth: 100, maxWidth: 200, tooltip: true },
    { title: "application", field: "application", minWidth: 100, maxWidth: 200, tooltip: true },
];

const AdminQuery = () => {
    const [input_query, setInputQuery] = React.useState("SELECT * FROM trend_invocations");

    const [log_query_data, setLogQueryResult] = React.useState(null);
    const [error, setError] = React.useState(false);

    const get_log_query = async (query) => {
        const { api_endpoint, headers } = await getApiDefaults();

        try {
            const response = await axios.get(`${api_endpoint}/admin/db_query`, {
                headers: headers,
                params: {
                    query: query,
                },
            });
            const data = await response.data;
            await setError(false);
            await setLogQueryResult(data);
        } catch (error) {
            setError(error.message);
        }
    };

    // make api call on mount using useEffect
    useEffect(() => {
        get_log_query(input_query);
        // set data to state
    }, []);

    console.log(log_query_data);

    const tabulator_options = {
        height: "100%",
        layout: "fitColumns",
        placeholder: "No Data Set",
        pagination: "local",
        paginationSize: 10,
        paginationSizeSelector: [20, 40, 60, 80, 100],
        movableColumns: true,
        resizableRows: false,
    };

    const columns =
        log_query_data && log_query_data.length > 0
            ? Object.keys(log_query_data[0]).map((key) => {
                  if (base_columns.find((col) => col.field === key)) {
                      return base_columns.find((col) => col.field === key);
                  } else {
                      return {
                          title: key,
                          field: key,
                          minWidth: 100,
                          maxWidth: 200,
                          tooltip: true,
                      };
                  }
              })
            : base_columns;

    return (
        // next container has elements in one column. All elements are centered
        <Div className="container-fluid">
            <div className="row">
                <div className="col-12 text-center">
                    <h2>Admin Queries</h2>

                    <div className="container text-start">
                        <h3>Tables</h3>

                        <ul
                            style={{
                                // listStyleType: "none",
                                textAlign: "left",
                            }}
                        >
                            <li>logs</li>
                            <li>states</li>
                            <li>overrides</li>
                            <li>trend_invocations</li>
                            <li>raw</li>
                            <li>trends</li>
                        </ul>

                        {/* <ul
                        style={{
                            listStyleType: "none",
                            textAlign: "left",
                        }}
                    >
                        <li>SELECT * FROM logs WHERE unique_name=="manuelsolalinde@bkvcorp.com" AND endpoint like "/daily%"</li>
                        <li>SELECT * FROM logs WHERE log_level=="ERROR"</li>
                        <li>SELECT * FROM logs WHERE log_level=="ERROR" AND log_time > "2023-01-15 11:29:02.983513"</li>
                        <li>SELECT * FROM logs WHERE log_msg like "% 500"</li>
                        </ul> */}
                    </div>
                    <div className="container text-start">
                        <h3>Query</h3>
                        <div className="row">
                            <div className="col-12">
                                <form
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        await get_log_query(input_query);
                                    }}
                                >
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter query here"
                                            aria-label="Enter query here"
                                            aria-describedby="button-addon2"
                                            value={input_query}
                                            onChange={(e) => setInputQuery(e.target.value)}
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            id="button-addon2"
                                            onClick={async () => await get_log_query(input_query)}
                                        >
                                            Execute
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {error ? (
                                    <div>Error:{error}</div>
                                ) : (
                                    <ReactTabulator
                                        data={log_query_data}
                                        columns={columns}
                                        layout={"fitColumns"}
                                        options={tabulator_options}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Div>
    );
};

export default AdminQuery;
