import React, { useEffect, Suspense, lazy } from "react";
import "./styles/App.css";
import "./styles/Tabs.css";

import { Route, BrowserRouter, Switch } from "react-router-dom"; //import router

//tabulator settings
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";

// authentication
import SignIn from "./pages/SignIn";
import {
    MsalProvider,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import { GlobalDebug } from "./utils/remove-consoles";
import GasTargetsApp from "./pages/GasTargetsApp";
import AdminDashboard from "./pages/AdminDashboard";
import UnderMaintenance from "./pages/UnderMaintenance";
import {
    // checkApiConnectionAction,
    getApiDefaults,
} from "bkv-components/store_lib/actions/dataActions";
import axios from "axios";
// import { TestArcgisMapSelection } from "./component_testing/TestArcgisMapSelection";
// import TestPlot from "./component_testing/TestPlot";
// const TestPlot = lazy(() => import("./component_testing/unused_TestPlot"));
const TestPlotV2 = lazy(() => import("./component_testing/TestPlot_v2"));


const MyTestPlotV2 = () => {
    return (
        <Suspense fallback={<div>Page is Loading...</div>}>
            <TestPlotV2 />
        </Suspense>
    );
};


function App(props) {
    const { msalInstance } = props;

    const [api_connection_error, setApiConnectionError] = React.useState(false);

    useEffect(() => {
        const apiConnectionCheck = async () => {
            const { api_endpoint, headers } = await getApiDefaults();
            try {
                var res1 = await axios.get(api_endpoint + "/", {
                    headers: headers,
                });
            } catch (e) {
                let response_exist = e.response ? true : false;
                if (window.navigator.onLine && !response_exist) {
                    setApiConnectionError(true);
                }
                if (response_exist) {
                    console.warn('api connection error with status code:', e.response.status);
                } else {
                    console.warn('api connection error with no response');
                }
                return false;
            }
            setApiConnectionError(false);
            return true;
        };

        apiConnectionCheck();

        // // check connection every 10 seconds
        // const interval = setInterval(async () => {
        //     let value = await apiConnectionCheck();
        //     if (value) {
        //         clearInterval(interval);
        //     }
        // }, 10000);

        // (process.env.NODE_ENV === "production" ||
        //     process.env.REACT_APP_ENV === "STAGING") &&
        //     GlobalDebug(false);


    }, []);


    if (api_connection_error) {
        //redirect to under maintenance page
        return <UnderMaintenance />;
    }

    return (
        <MsalProvider instance={msalInstance}>
            <div className="App">
                <AuthenticatedTemplate>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={GasTargetsApp} />
                            <Route exact path="/plotsv2" component={MyTestPlotV2} />
                            <Route
                                exact
                                path="/admin"
                                component={AdminDashboard}
                            />
                            <Route
                                exact
                                path="/maintenance"
                                component={UnderMaintenance}
                            />
                        </Switch>
                    </BrowserRouter>
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                    <SignIn />
                </UnauthenticatedTemplate>
            </div>
        </MsalProvider>
        // </ErrorBoundary>
    );
}

export default App;
